import i18n from "i18next";
import Backend from "i18next-http-backend";
import prefix from "/src/common/prefix";

const isDebug = process.env.BUILD_FLAG_IS_DEV === "true";

i18n.use(Backend).init(
  {
    debug: isDebug,
    fallbackLng: {
      "zh-HK": ["zh-Hant"],
      "zh-TW": ["zh-Hant"],
      "zh-CN": ["zh-Hans"],
      zh: ["zh-Hans"],
      en: ["en"],
      default: ["zh-Hant"],
    },
    whiteList: ["zh-Hant", "zh-Hans", "en"],
    load: "currentOnly",
    // have a common namespace used around the full app
    ns: ["common", "auth", "error"],
    defaultNS: "common",
    // preload: ['zh-Hant', 'zh-Hans', 'en'],
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: true,
    },
    backend: {
      // "loadPath": "/locales/{{lng}}/{{ns}}.json"
      loadPath(lng, ns) {
        let locale;
        switch (lng) {
          case "zh-HK":
          case "zh-TW":
          case "zh-Hant":
            locale = "zh-Hant";
            break;
          case "zh-CN":
          case "zh":
            locale = "zh-Hans";
            break;
          case "en":
            locale = "en";
            break;
          default:
            locale = lng;
            break;
        }
        return `${prefix.filePrefix}/locales/${locale}/${ns}.json`;
      },
    },
  },
  (err, t) => {
    if (err) {
      return console.error(err);
    }
    console.log("i18n client successfully initialized");
  }
);

export default i18n;
