import React from "react";
import { Button } from "react-toolbox";
import { useTranslation } from "react-i18next";
import acceptFormValidation from "./acceptFormValidation";
import { Field, Form } from "react-final-form";
import RenderInput from "/src/components/Input/FormInput";
import { AcceptCreatePayload } from "/src/types/api/data-contracts";

interface Props {
  onSubmit: (data: AcceptCreatePayload) => void;
  isFormSubmitting: boolean;
}

function MemberAcceptForm(props: Props): JSX.Element {
  const { t } = useTranslation("member");

  const handleSubmit = async (value: AcceptCreatePayload) => {
    props.onSubmit(value);
  };

  return (
    <Form<AcceptCreatePayload>
      onSubmit={handleSubmit}
      validate={acceptFormValidation}
    >
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            type="text"
            label={t("accept_invitation_form.name_field")}
            component={RenderInput}
            required
          />
          <Field
            name="password"
            type="password"
            label={t("accept_invitation_form.password_field")}
            component={RenderInput}
            required
          />
          <Field
            name="password_confirmation"
            type="password"
            label={t("accept_invitation_form.password_confirmation_field")}
            component={RenderInput}
            required
          />
          <Button
            label={t("accept_invitation_form.action.submit")}
            type="submit"
            disabled={props.isFormSubmitting}
            raised
            primary
            style={{ width: "100%" }}
          />
        </form>
      )}
    </Form>
  );
}

export default MemberAcceptForm;
