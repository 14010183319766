import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountArticleGroupCreatePayload } from "/src/types/api/data-contracts";

interface State {
  selectedArticle: ReduxAccountArticle[];
}

const initialState: State = {
  selectedArticle: [],
};

export type ReduxAccountArticle = AccountArticleGroupCreatePayload[0] & {
  thumb_url?: string | null;
  id?: string;
};

export const articleSlice = createSlice({
  name: "article",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addSelectedArticle: (state, action: PayloadAction<ReduxAccountArticle>) => {
      state.selectedArticle.push(action.payload);
    },
    updateArticleContent: (
      state,
      action: PayloadAction<{
        currentItem: ReduxAccountArticle;
        newItem: ReduxAccountArticle;
      }>
    ) => {
      // get the index, then overwrite the article item
      const index = state.selectedArticle.findIndex(
        (item) => item.id === action.payload.currentItem.id
      );
      state.selectedArticle[index] = action.payload.newItem;
    },
    deleteSelectedArticle: (
      state,
      action: PayloadAction<ReduxAccountArticle>
    ) => {
      const index = state.selectedArticle.findIndex(
        (item) => item.id === action.payload.id
      );
      state.selectedArticle.splice(index, 1);
    },
    updateSelectedArticleOrder: (
      state,
      action: PayloadAction<{
        dragItem: ReduxAccountArticle;
        dropItem: ReduxAccountArticle;
      }>
    ) => {
      const dragItemIndex = state.selectedArticle.findIndex(
        (item) => item.id === action.payload.dragItem.id
      );
      const dropItemIndex = state.selectedArticle.findIndex(
        (item) => item.id === action.payload.dropItem.id
      );
      state.selectedArticle.splice(dragItemIndex, 1);
      state.selectedArticle.splice(dropItemIndex, 0, action.payload.dragItem);
    },
    clearSelectedArticles: (state) => {
      state.selectedArticle = [];
    },
  },
});

export const {
  addSelectedArticle,
  deleteSelectedArticle,
  updateArticleContent,
  updateSelectedArticleOrder,
  clearSelectedArticles,
} = articleSlice.actions;

export default articleSlice.reducer;
