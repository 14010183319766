import PropTypes from "prop-types";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Snackbar, Card, CardTitle } from "react-toolbox";
import { bindActionCreators, compose } from "redux";
import { submit, isDirty } from "redux-form";

import ForgetPasswordForm from "/src/components/ForgetPasswordForm/ForgetPasswordForm";
import * as authActions from "store/reducers/auth";
import * as styles from "./ForgetPassword.scss";

const formName = "forgetPassword";

class ForgetPassword extends Component {
  state = {
    isSubmitting: false,
    isSubmitted: false,
    snackbarAction: this.props.t("forget_password.snackbar_dismiss"),
    isSnackbarActive: false,
    snackbarLabel: "",
    snackbarType: "cancel",
  };

  componentDidMount() {
    //this.unblockRouter = this.props.history.block(this.routerWillLeave);
  }

  componentWillUnmount() {
    //this.unblockRouter();
  }

  handleSubmit = (data) => {
    if (!this.state.isSubmitting) {
      this.setState({
        isSubmitting: true,
        isSubmitted: false,
      });

      this.props.moduleActs
        .forgetPassword(data)
        .then(this.handleSubmitSuccess, this.handleSubmitFail)
        .catch(this.handleCatchError);
    }
  };

  handleSubmitSuccess = (res) => {
    // if (res.status_code !== 200) {
    //   this.handleCatchError(res.message_code);
    //   return;
    // }

    this.setState({
      isSubmitted: true,
      isSubmitting: false,
    });
  };

  handleSubmitFail = (error) => {
    this.setState({
      isSubmitting: false,
      isSubmitted: false,
      // error: error.message,
    });

    if (error.errors) {
      return this.handleInvalidURL(error.errors);
    }

    const snackbarHint = `Error: ${error.status_code} ${error.message}`;
    return this.handleSnackbarShow(snackbarHint);
  };

  handleCatchError = (error) => {
    console.log("SOMETHING GONE WRONG", error);
    const snackbarHint = this.props.t("forget_password.message.unknown_error");
    this.handleSnackbarShow(snackbarHint);
  };

  handleInvalidURL = (errors) => {
    this.handleSnackbarShow(`${errors.token || ""} ${errors.email || ""}`);
  };

  handleSnackbarTimeout = () => {
    this.handleSnackbarHide();
  };

  handleSnackbarClick = () => {
    this.handleSnackbarHide();
  };

  handleSnackbarShow = (hint) => {
    this.setState({ snackbarLabel: hint, isSnackbarActive: true });
  };

  handleSnackbarHide = () => {
    this.setState({ isSnackbarActive: false });
  };

  routerWillLeave = () => {
    if (
      (this.props.dirty && !this.state.isSubmitted) ||
      this.state.isSubmitting
    ) {
      return this.props.t("forget_password.message.confirm_leave");
    }
    return true;
  };

  render() {
    const { forgetPwdError, t } = this.props;

    return (
      <div className={styles.container}>
        {!this.state.isSubmitted && (
          <div>
            <Card style={{ width: "350px", padding: "2rem" }}>
              <CardTitle
                avatar="/assets/images/initsoc_logo.png"
                title={t("forget_password.header")}
              />
              <ForgetPasswordForm
                onSubmit={this.handleSubmit}
                errorMsg={forgetPwdError ? forgetPwdError.errors : null}
                isFormSubmitting={this.state.isSubmitting}
              />
            </Card>
            <div style={{ padding: "1rem", fontSize: "1.2rem" }}>
              <Link to={"/auth/login"}>{t("forget_password.action.back")}</Link>
            </div>
          </div>
        )}
        {
          <section>
            <Snackbar
              action={this.state.snackbarAction}
              active={this.state.isSnackbarActive}
              label={this.state.snackbarLabel}
              timeout={12000}
              onClick={this.handleSnackbarClick}
              onTimeout={this.handleSnackbarTimeout}
              type={this.state.snackbarType}
            />
          </section>
        }
        {this.state.isSubmitted && (
          <div style={{ textAlign: "center" }}>
            {t("forget_password.message.submit_success")}
          </div>
        )}
      </div>
    );
  }
}

ForgetPassword.propTypes = {
  history: PropTypes.object,
  dirty: PropTypes.bool,
  forgetPwdError: PropTypes.object,
  moduleActs: PropTypes.shape({
    forgetPassword: PropTypes.func.isRequired,
  }),
};

export default compose(
  connect(
    (state) => ({
      dirty: isDirty(formName)(state),
      forgetPwdError: state.auth.forgetPwdError,
    }),
    (dispatch) => ({
      moduleActs: bindActionCreators(authActions, dispatch),
      customActs: {
        submitForm: (form) => {
          dispatch(submit(form));
        },
      },
    })
  ),
  withTranslation("auth")
)(ForgetPassword);
