import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import errorReducer from "./reducers/error";
import followerReducer from "./reducers/follower";
import messageSelectorReducer from "./reducers/messageSelector";
import autoReplyReducer from "./reducers/autoReply";
import articleReducer from "./reducers/articles";
import menuReducer from "./reducers/menu";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    error: errorReducer,
    follower: followerReducer,
    messageSelector: messageSelectorReducer,
    autoReply: autoReplyReducer,
    article: articleReducer,
    menu: menuReducer,
  },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
