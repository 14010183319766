import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  IconButton,
  List,
  ListItem,
  ListSubHeader,
  ListDivider,
} from "react-toolbox";
import { ListItemContent } from "react-toolbox/lib/list";
import { useTranslation } from "react-i18next";

import { Row } from "react-flexbox-grid";
import Avatar from "/src/components/Display/Avatar";

import { useGetOrgAccountList } from "/src/utils/reactQuery/org";
import { useAppSelector } from "/src/store/hooks";
import styles from "./Sidebar.scss";
import type { Account } from "/src/utils/reactQuery/org";
import { accRoles } from "/src/constants/roles";

function SidebarList() {
  const [isShowOrgList, setIsShowOrgList] = useState(true);
  const { t } = useTranslation(["common", "account"]);
  // const { accountId } = useTypedRouteParams();
  const organizationId = useAppSelector((state) => state.auth.orgId);
  if (!organizationId) {
    throw new Error("Organization ID is required.");
  }
  const { accountList, isFetched: isAccountListLoaded } =
    useGetOrgAccountList(organizationId);
  const accountId = useAppSelector((state) => state.auth.lastAccessAccountId);
  const selectedAccount = useAppSelector((state) => state.auth.selectedAccount);
  const [account, setAccount] = useState<Account | null>(null);

  // when user logged in, their last access account id will be set to redux
  // get current selected account details if not available by the last access account id
  useEffect(() => {
    if (account === null && isAccountListLoaded) {
      const matchedAccount =
        Array.from(accountList.values()).find(
          (account) => account.id === accountId
        ) ?? null;
      setAccount(matchedAccount);
    }
  }, [account, isAccountListLoaded, accountId, accountList.size, accountList]);

  // TODO permission is unclear in current scope, so just always display this module for now
  const isAllowDisplayAccountModule = () => {
    // const {
    //   authorizerAccountRoles,
    //   match: { params: { accountId } },
    //   authorizerOrgId,
    //   authorizerOrgRoles,
    // } = this.props;
    // const accountRole = authorizerAccountRoles[accountId];
    // const orgRole = authorizerOrgRoles[authorizerOrgId];

    // return accountRole === ACC_ADMIN || accountRole === ACC_EDITOR || orgRole === ORG_ADMIN;

    // if user does not assocaited with any wechat account, should not display the account module
    if (
      selectedAccount &&
      (selectedAccount.role_name === accRoles.ACC_ADMIN ||
        selectedAccount.role_name === accRoles.ACC_EDITOR)
    )
      return true;
    else return false;
  };

  //const account = this.props.accounts.find(acc => acc.id === parseInt(accountId, 10));

  const isDisplayAccountModule = isAllowDisplayAccountModule();

  return (
    <List selectable ripple>
      {selectedAccount && (
        <ListItem ripple={false} selectable={false}>
          <Avatar image={selectedAccount.profile_url} type="image" />
          <ListItemContent>
            <Row className={styles.rowFix}>
              <h5 className={styles.cardTitle}>{selectedAccount.nickname}</h5>
            </Row>
            <Row>
              <div className={styles.cardSubtitleContainer}>
                <p className={styles.cardSubtitle}>
                  {t(
                    `account:service_type.${selectedAccount.service_type?.toLowerCase()}`
                  )}
                </p>
              </div>
            </Row>
          </ListItemContent>
        </ListItem>
      )}

      {/* <ListDivider /> */}
      {/* <ListSubHeader caption={t("sidebar.account.subheader.analytics")} />
      <NavLink
        exact
        to={`/org/${organizationId}/account/${accountId}/overview`}
        activeClassName={styles.active}
      >
        <ListItem
          selectable
          caption={t("sidebar.account.analytics_section.overview")}
          leftIcon={<i className="fa fa-bar-chart" />}
        />
      </NavLink>
      <NavLink
        // exact
        to={`/org/${organizationId}/account/${accountId}/overview/broadcast`}
        activeClassName={styles.active}
      >
        <ListItem
          selectable
          caption={t("sidebar.account.analytics_section.broadcast")}
          leftIcon={<i className="fa fa-bar-chart" />}
        />
      </NavLink>
      <NavLink
        exact
        to={`/org/${organizationId}/account/${accountId}/overview/follower`}
        activeClassName={styles.active}
      >
        <ListItem
          selectable
          caption={t("sidebar.account.analytics_section.follower")}
          leftIcon={<i className="fa fa-bar-chart" />}
        />
      </NavLink>
      <NavLink
        exact
        to={`/org/${organizationId}/account/${accountId}/overview/article`}
        activeClassName={styles.active}
      >
        <ListItem
          selectable
          caption={t("sidebar.account.analytics_section.article")}
          leftIcon={<i className="fa fa-bar-chart" />}
        />
      </NavLink>
      <NavLink
        exact
        to={`/org/${organizationId}/account/${accountId}/overview/message`}
        activeClassName={styles.active}
      >
        <ListItem
          selectable
          caption={t("sidebar.account.analytics_section.message")}
          leftIcon={<i className="fa fa-bar-chart" />}
        />
      </NavLink>
      <NavLink
        exact
        to={`/org/${organizationId}/account/${accountId}/overview/menu`}
        activeClassName={styles.active}
      >
        <ListItem
          selectable
          caption={t("sidebar.account.analytics_section.menu")}
          leftIcon={<i className="fa fa-bar-chart" />}
        />
      </NavLink> */}
      {isDisplayAccountModule && (
        <>
          <List>
            <ListDivider />
            <ListSubHeader
              caption={t("sidebar.account.subheader.user_activities")}
            />
            <NavLink
              exact
              to={`/org/${organizationId}/account/${accountId}/follower`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.account.follower")}
                leftIcon="people"
              />
            </NavLink>
            {/* <NavLink
              exact
              to={`/org/${organizationId}/account/${accountId}/inbox`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.account.inbox")}
                leftIcon="inbox"
              />
            </NavLink> */}

            <ListDivider />
            <ListSubHeader caption={t("sidebar.account.subheader.creative")} />
            <NavLink
              to={`/org/${organizationId}/account/${accountId}/broadcast`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.account.broadcast")}
                leftIcon={<i className="fa fa-bullhorn" />}
                /* leftIcon={<i className="fa fa-podcast"></i>} */
              />
            </NavLink>
            <NavLink
              to={`/org/${organizationId}/account/${accountId}/article`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.account.article")}
                leftIcon={<i className="fa fa-file-text" />}
              />
            </NavLink>
            <NavLink
              exact
              to={`/org/${organizationId}/account/${accountId}/material`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.account.materials")}
                leftIcon="photo_library"
              />
            </NavLink>

            <ListDivider />
            <ListSubHeader caption={t("sidebar.account.subheader.settings")} />
            <NavLink
              exact
              to={`/org/${organizationId}/account/${accountId}/menu`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.account.menus")}
                leftIcon={<i className="fa fa-list-alt" />}
              />
            </NavLink>
            <NavLink
              exact
              to={`/org/${organizationId}/account/${accountId}/schedule-menu`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.account.schedule_menus")}
                leftIcon={<i className="fa fa-list-alt" />}
              />
            </NavLink>
            <NavLink
              to={`/org/${organizationId}/account/${accountId}/auto-reply`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.account.auto_reply")}
                leftIcon={<i className="fa fa-reply" />}
              />
            </NavLink>
            {/* <NavLink exact to={`/account/${accountId}/qrcode`} activeClassName={styles.active}>
                <ListItem
                  selectable
                  caption="QR Code"
                  leftIcon={<i className="fa fa-qrcode"></i>}
                />
              </NavLink>
              <ListDivider />
              <ListSubHeader caption="More" />
              <NavLink exact to={`/account/${accountId}/store-locator`} activeClassName={styles.active}>
                <ListItem
                  selectable
                  caption="Store Locator"
                  leftIcon="store_mall_directory"
                />
              </NavLink> */}
          </List>
          <ListDivider />
        </>
      )}
      <ListSubHeader caption={t("sidebar.home.subheader.menu")} />
      <NavLink to="/home/account" activeClassName={styles.active}>
        <ListItem
          selectable
          caption={t("sidebar.home.account")}
          leftIcon={<i className="fa fa-wechat" />}
        />
      </NavLink>
      <ListDivider />
      <ListSubHeader caption={t("sidebar.home.subheader.manage")} />
      <ListItem
        selectable={false}
        ripple={false}
        caption={t("sidebar.home.organization")}
        leftIcon={<i className="fa fa-briefcase" />}
        rightIcon={
          <IconButton
            icon={
              <i
                className={
                  isShowOrgList ? "fa fa-angle-up" : "fa fa-angle-down"
                }
              />
            }
            onClick={() => setIsShowOrgList(!isShowOrgList)}
          />
        }
      />
      <div>
        {!!isShowOrgList && (
          <List>
            <NavLink
              to={`/org/${organizationId}/account`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.home.account")}
                leftIcon={<i className="fa fa-wechat" />}
                className={styles.orgListItem}
              />
            </NavLink>
            <NavLink
              to={`/org/${organizationId}/member`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.home.member")}
                leftIcon={<i className="fa fa-users" />}
                className={styles.orgListItem}
              />
            </NavLink>
            <NavLink
              to={`/org/${organizationId}/profile`}
              activeClassName={styles.active}
            >
              <ListItem
                selectable
                caption={t("sidebar.home.profile")}
                leftIcon={<i className="fa fa-id-card-o" />}
                className={styles.orgListItem}
              />
            </NavLink>
          </List>
        )}
      </div>
    </List>
  );
}

export default SidebarList;
