import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateMenuPayload, Menu } from "/src/utils/reactQuery/menu";

interface MenuState {
  menuItem: CreateMenuPayload[];
}

// type used on create/update menu which may have difference with GET menu response
export type MenuItem = CreateMenuPayload;
export type SubMenuItem = NonNullable<CreateMenuPayload["sub_button"]>[0];

const initialState: MenuState = {
  menuItem: [],
};

/**
 * Function to remove the menu item key property
 * @param T - Specify the correct function type, MenuItem or SubMenuItem only
 * @param menuItem - MenuItem or Sub-menu Item
 * @param removeSubItemKey - Boolean to determine removing sub-menu item's key
 * @returns removed key of menu item
 */
function keyRemoval(menuItem: MenuItem): MenuItem {
  delete menuItem.id;
  return menuItem;
}

const formatMessage = (message: Menu["button"][0]["message"]) => {
  if (message) {
    if (message.message_type === "TEXT" && message.messageable.text_content) {
      return { text_content: message.messageable.text_content };
    }
    if (
      (message.message_type === "IMAGE" ||
        message.message_type === "VIDEO" ||
        message.message_type === "VOICE") &&
      message.messageable.material_id
    ) {
      return { material_id: message.messageable.material_id };
    }
    if (
      message.message_type === "NEWS" &&
      message.messageable.article_group_id
    ) {
      return { article_group_id: message.messageable.article_group_id };
    }
  }
  return {};
};

const transformToMenuItem = (
  data: Menu["button"][0] | NonNullable<Menu["button"][0]["sub_button"]>[0]
): CreateMenuPayload => {
  return {
    type: data.type,
    name: data.name,
    ...(data.id && { id: data.id }),
    ...(data.url && { url: data.url }),
    ...(data.message && {
      message: formatMessage(data.message),
    }),
  };
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    /**
     * Function to transform GET Menu API response to CreateMenuPayload structure
     * @param payload {Menu Structure}
     */
    changeMenuItems: (state, action: PayloadAction<Menu["button"]>) => {
      const menu: CreateMenuPayload[] = [];
      action.payload.forEach((payload) => {
        menu.push({
          ...transformToMenuItem(payload),
          sub_button:
            payload.sub_button &&
            payload.sub_button.map((subMenu) => {
              return {
                ...transformToMenuItem(subMenu),
              };
            }),
        });
      });
      state.menuItem = menu;
    },
    addNewMenuItem: (state) => {
      state.menuItem.push({
        name: "menu",
        type: "view",
        url: "",
      });
    },
    /**
     * Function to change the content of parent/root menu item (the bottom three menu), this function will remove the key property automatically
     * @param menuIndex {number} - indicate the position of item to be updated
     * @param menuItem {CreateMenuPayload} - new menu item content
     */
    updateMenuItem: (
      state,
      action: PayloadAction<{
        menuIndex: number;
        menuItem: MenuItem;
      }>
    ) => {
      // update the menu item and remove the existing key property and its value
      state.menuItem[action.payload.menuIndex] = keyRemoval(
        action.payload.menuItem
      );
    },
    removeMenuItem: (state, action: PayloadAction<number>) => {
      state.menuItem.splice(action.payload, 1);
    },
    addNewSubMenuItem: (state, action: PayloadAction<number>) => {
      if (!state.menuItem[action.payload].sub_button) {
        // if the selected menu is not transfered to sub-menu style
        state.menuItem[action.payload] = {
          name: state.menuItem[action.payload].name,
        };
        state.menuItem[action.payload].sub_button = [];
      }
      state.menuItem[action.payload].sub_button?.push({
        type: "view",
        name: "sub menu",
        url: "",
      });
    },
    /**
     * Function to change the content of sub-menu item, this function will remove the key property automatically
     * @param menuIndex {number} - indicate the position of parent/root menu item to be updated
     * @param subMenuIndex {number} - indicate the position of sub-meni item to be updated
     * @param menuItem {CreateMenuPayload} - new sub-menu item content
     */
    updateSubMenuItem: (
      state,
      action: PayloadAction<{
        menuIndex: number;
        subMenuIndex: number;
        subMenu: SubMenuItem;
      }>
    ) => {
      const menuItem = state.menuItem[action.payload.menuIndex];
      if (menuItem.sub_button) {
        // remove id from existing sub-menu item
        delete action.payload.subMenu.id;
        // update the sub-menu item and remove the existing key property and its value
        menuItem.sub_button[action.payload.subMenuIndex] =
          action.payload.subMenu;
        state.menuItem[action.payload.menuIndex] = keyRemoval(menuItem);
      }
    },
    removeSubMenuItem: (
      state,
      action: PayloadAction<{ menuIndex: number; subMenuIndex: number }>
    ) => {
      const menuItem = state.menuItem[action.payload.menuIndex];
      if (menuItem.sub_button) {
        menuItem.sub_button.splice(action.payload.subMenuIndex, 1);
        // when all sub-menu item removed, switch the menu item back to normal
        if (menuItem.sub_button.length === 0) {
          menuItem.type = "view";
          menuItem.url = "";
          delete menuItem.sub_button;
          delete menuItem.id;
        }
      }
    },
    updateMenuItemOrder: (
      state,
      action: PayloadAction<{ from: number; to: number }>
    ) => {
      const temp = state.menuItem[action.payload.to];
      state.menuItem[action.payload.to] = state.menuItem[action.payload.from];
      state.menuItem[action.payload.from] = temp;
    },
    updateSubMenuItemOrder: (
      state,
      action: PayloadAction<{ menuIndex: number; from: number; to: number }>
    ) => {
      const selectedMenu = state.menuItem[action.payload.menuIndex];
      if (selectedMenu.sub_button) {
        const temp = selectedMenu.sub_button[action.payload.to];
        selectedMenu.sub_button[action.payload.to] =
          selectedMenu.sub_button[action.payload.from];
        selectedMenu.sub_button[action.payload.from] = temp;
      }
    },
  },
});

export const {
  changeMenuItems,
  updateMenuItemOrder,
  updateSubMenuItemOrder,
  addNewMenuItem,
  updateMenuItem,
  removeMenuItem,
  addNewSubMenuItem,
  updateSubMenuItem,
  removeSubMenuItem,
} = menuSlice.actions;

export default menuSlice.reducer;
