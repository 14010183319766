.tooltip {
  display: block;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  max-width: 170px;
  padding: 5px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  text-transform: none;
  transform: scale(0) translateX(-50%);
  transform-origin: top left;
  transition: cubic-bezier(0.4, 0, 0.2, 1) 200ms transform;
  z-index: 200;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%
}
.tooltip *,
    .tooltip *::after,
    .tooltip *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.tooltip.tooltipActive {
    transform: scale(1) translateX(-50%);
  }
.tooltip.tooltipTop {
    transform: scale(0) translateX(-50%) translateY(-100%)
  }
.tooltip.tooltipTop.tooltipActive {
      transform: scale(1) translateX(-50%) translateY(-100%);
    }
.tooltip.tooltipLeft {
    transform: scale(0) translateX(-100%) translateY(-50%)
  }
.tooltip.tooltipLeft.tooltipActive {
      transform: scale(1) translateX(-100%) translateY(-50%);
    }
.tooltip.tooltipRight {
    transform: scale(0) translateX(0) translateY(-50%)
  }
.tooltip.tooltipRight.tooltipActive {
      transform: scale(1) translateX(0) translateY(-50%);
    }
.tooltipInner {
  background: rgba(97, 97, 97, 0.9);
  border-radius: 2px;
  color: rgb(255, 255, 255);
  display: block;
  padding: 8px;
}
