import React from "react";
import prefix from "/src/common/prefix";

const defaultSize = 4; // 4rem
const backgroundColors = [
  // reference: https://material.io/guidelines/style/color.html
  "#C62828",
  "#AD1457",
  "#6A1B9A",
  "#4527A0",
  "#283593",
  "#1565C0",
  "#0277BD",
  "#00838F",
  "#00695C",
  "#2E7D32",
  "#558B2F",
  "#EF6C00",
  "#D84315",
  "#4E342E",
  "#424242",
  "#37474F",
  "#d3812f",
  "#FF5252",
  "#FF4081",
  "#E040FB",
  "#7C4DFF",
  "#536DFE",
  "#FFAB00",
];

const TYPE_TEXT = "text";
const TYPE_IMAGE = "image";

interface AvatarProps {
  name?: string;
  size?: number;
  image?: string | null;
  type?: string;
}

function Avatar(props: AvatarProps): JSX.Element {
  const getAvatarText = (name: string | undefined) => {
    return name ? name.charAt(0).toUpperCase() : "@";
  };

  const getAvatarColor = () => {
    const text = getAvatarText(props.name);
    const colorCount = backgroundColors.length;
    const index = Math.floor(text.charCodeAt(0) % colorCount);

    return backgroundColors[index];
  };

  const getFontSize = () => {
    const size = props.size || defaultSize;

    // scale font size using avatar size
    return `${size * 0.4}rem`;
  };

  const getAvatarStyle = (): React.CSSProperties => {
    const size = `${props.size || defaultSize}rem`;

    return {
      width: size,
      height: size,
      position: "relative",
      overflow: "hidden",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      backgroundColor: props.type === TYPE_TEXT ? getAvatarColor() : "#bdbdbd",
      justifyContent: "center",
    };
  };

  const getTextStyle = (): React.CSSProperties => ({
    fontWeight: "bold",
    color: "#fff",
    fontSize: getFontSize(),
  });

  const styles = {
    avatar: getAvatarStyle(),
    text: getTextStyle(),
  };

  return (
    <div style={styles.avatar}>
      {props.type === TYPE_TEXT && !props.image && (
        <p style={styles.text}>{getAvatarText(props.name)}</p>
      )}
      {(!props.type || props.type === TYPE_IMAGE) && (
        <img
          // TODO: use proper default avatar
          src={
            props.image ||
            `${prefix.filePrefix}/assets/images/default_avatar.jpg`
          }
          role="presentation"
          style={{
            display: "inline",
            height: "100%",
            width: "auto",
            margin: "0 auto",
          }}
          alt=""
        />
      )}
    </div>
  );
}

export default Avatar;
