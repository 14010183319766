import React from "react";
import { Dropdown, Menu, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "/src/store/hooks";
import { selectAccount } from "/src/store/reducers/auth";
import { useGetOrgAccountList } from "/src/utils/reactQuery/org";
import { CheckCircleOutlined, DownOutlined } from "@ant-design/icons";

function SwitchAccountButton(): JSX.Element {
  const { t } = useTranslation("account");
  const orgId = useAppSelector((state) => state.auth.orgId);
  const selectedAccount = useAppSelector((state) => state.auth.selectedAccount);
  const selectedAccountId = useAppSelector(
    (state) => state.auth.lastAccessAccountId
  );
  const dispatch = useAppDispatch();
  const accountList = useAppSelector((state) => state.auth.user?.accounts);

  const RenderAccountSwitchMenu = () => (
    <Menu
      selectable
      defaultSelectedKeys={[selectedAccountId?.toString() ?? ""]}
      onSelect={({ key }) => {
        //do nothing
        if (!accountList) return;
        const selectedAccount = accountList.find(
          (account) => account.id === parseInt(key, 10)
        );
        if (selectedAccount) {
          dispatch(selectAccount(selectedAccount));
        }
      }}
    >
      {accountList &&
        accountList.map((account) => (
          <Menu.Item key={account.id}>
            <Tag color="geekblue">
              {account.service_type
                ? t(
                    `account:service_type.${account.service_type.toLowerCase()}`
                  )
                : ""}
            </Tag>
            {account.nickname}
            {!!account.is_verified && (
              <CheckCircleOutlined className="ml-2 align-baseline text-green-400" />
            )}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={RenderAccountSwitchMenu()}
      trigger={["click"]}
      className="mr-8 self-center"
    >
      {/* Add dropdown for switching account */}
      {/* After switching account, frontend should redirect user to home page */}
      <a onClick={(e) => e.preventDefault()}>
        <>
          {selectedAccount && (
            <>
              <Tag color="geekblue">
                {selectedAccount.service_type
                  ? t(
                      `account:service_type.${selectedAccount.service_type.toLowerCase()}`
                    )
                  : ""}
              </Tag>
              {selectedAccount.nickname}
              {!!selectedAccount.is_verified && (
                <CheckCircleOutlined className="ml-2 align-baseline text-green-400" />
              )}
            </>
          )}
          {!selectedAccount && <>Switch Account</>}
          <DownOutlined className="ml-2" />
        </>
      </a>
    </Dropdown>
  );
}

export default SwitchAccountButton;
