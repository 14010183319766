.tabs {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
}
.tabs *,
    .tabs *::after,
    .tabs *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.navigation {
  box-shadow: inset 0 -1px rgb(238, 238, 238);
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  position: relative;
}
.navigationContainer {
  display: flex
}
.navigationContainer .navigation {
    flex: 1;
  }
.arrow {
  color: rgb(0, 0, 0);
  padding: 0 12px;
}
.arrowContainer {
  align-items: center;
  box-shadow: inset 0 -1px rgb(238, 238, 238);
  cursor: pointer;
  display: flex;
}
.icon {
  display: block;
  height: 24px;
  line-height: 24px;
  margin: 0 auto;
}
.label {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 17px 12px;
  position: relative;
  text-transform: uppercase;
  transition-duration: 0.35s;
  transition-property: background-color, box-shadow, color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.label:focus {
    background-color: rgba(255, 255, 255, 0.1);
    outline: none;
  }
.label > .rippleWrapper {
    overflow: hidden;
  }
.label.active {
    color: rgb(0, 0, 0);
  }
.label.disabled {
    opacity: 0.2;
  }
.label:not(.disabled) {
    cursor: pointer;
  }
.label.hidden {
    display: none;
  }
.label.withIcon {
    padding-bottom: 13px;
    padding-top: 13px;
    text-align: center;
  }
.label.withText .icon {
      margin-bottom: 8px;
    }
.pointer {
  background-color: rgb(63, 81, 181);
  height: 2px;
  margin-top: -2px;
  position: absolute;
  transition-duration: 0.35s;
  transition-property: left, width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 0;
}
.tab {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 17px 12px;
  position: relative
}
.tab:not(.active) {
    display: none;
  }
.tab.active {
    display: flex;
  }
.fixed .label {
    flex: 1;
    text-align: center;
  }
.inverse .navigation,
  .inverse .arrowContainer {
    background-color: rgb(63, 81, 181);
  }
.inverse .label {
    color: rgba(255, 255, 255, 0.3)
  }
.inverse .label.active {
      color: rgb(255, 255, 255);
    }
.inverse .arrow {
    color: rgb(255, 255, 255);
  }
.inverse .pointer {
    background-color: rgb(255, 64, 129);
  }
