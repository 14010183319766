import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Article } from "/src/utils/reactQuery/article";
import { Message } from "/src/utils/reactQuery/message";
import cloneDeep from "lodash-es/cloneDeep";
import { Material } from "/src/utils/reactQuery/material";
import { findIndex } from "lodash-es";

interface MessageSelectorState {
  messageList: Message[];
  articleList: Article[];
  materialList: Material[];
  selectedArticle: Article[];
}

const initialState: MessageSelectorState = {
  messageList: [],
  articleList: [],
  materialList: [],
  selectedArticle: [],
};

export const messageSelectorSlice = createSlice({
  name: "messageSelector",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addSelectedArticle: (state, action: PayloadAction<Article>) => {
      state.selectedArticle.push(action.payload);
    },
    deleteSelectedArticle: (state, action: PayloadAction<Article>) => {
      const index = state.selectedArticle.findIndex(
        (article) => article.id === action.payload.id
      );
      if (index !== -1) {
        state.selectedArticle.splice(index, 1);
      }
    },
    updateSelectedArticleOrder: (
      state,
      action: PayloadAction<{ drag: Article; drop: Article }>
    ) => {
      //find the dragged and dropped article position
      const dragIndex = findIndex(state.selectedArticle, [
        "id",
        action.payload.drag.id,
      ]);

      const dropIndex = findIndex(state.selectedArticle, [
        "id",
        action.payload.drop.id,
      ]);

      //if both of them are in selected list
      //try to remove the dragged article from list
      //and then insert it back to new position
      if (dragIndex !== -1 && dropIndex !== -1) {
        const selectedList = cloneDeep(state.selectedArticle);
        selectedList.splice(dragIndex, 1);
        if (dropIndex === 0) {
          selectedList.splice(0, 0, action.payload.drag);
        } else {
          selectedList.splice(dropIndex, 0, action.payload.drag);
        }
        state.selectedArticle = selectedList;
      }
    },
    setSelectedArticle: (state, action: PayloadAction<Article[]>) => {
      state.selectedArticle = action.payload;
    },
    clearSelectedArticles: (state) => {
      state.selectedArticle = [];
    },
  },
});

export const {
  addSelectedArticle,
  deleteSelectedArticle,
  updateSelectedArticleOrder,
  setSelectedArticle,
  clearSelectedArticles,
} = messageSelectorSlice.actions;

export default messageSelectorSlice.reducer;
