import * as React from "react";
import { logout } from "/src/types/api/Login";
import { useDispatch } from "react-redux";
import { loggedOut, setUser } from "/src/store/reducers/auth";
import { useMutation } from "react-query";
import { Button } from "react-toolbox";
import { useTranslation } from "react-i18next";

interface Props {
  onLogout: () => void;
  onLogoutSuccess: () => void;
}

function LogoutButton(props: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const startLogout = () => {
    props.onLogout();
    logoutMutation.mutate();
  };

  const logoutMutation = useMutation(
    () => {
      return logout();
    },
    {
      onSuccess: () => {
        dispatch(loggedOut());
        props.onLogoutSuccess();
      },
    }
  );

  return (
    <Button
      style={{ pointerEvents: "auto", flex: 1 }}
      label={t("navbar.sign_out")}
      onClick={() => startLogout()}
      disabled={logoutMutation.isLoading}
      raised
      primary
    />
  );
}

export default LogoutButton;
