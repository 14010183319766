import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Switch, Redirect, Route, Link, NavLink } from "react-router-dom";
import {
  Layout,
  Panel,
  NavDrawer,
  AppBar,
  Button,
  Card,
  CardActions,
  CardTitle,
  Navigation,
  IconMenu,
} from "react-toolbox";
import Sidebar from "/src/containers/Layout/Home/Sidebar";
import LoginStatus from "./LoginStatus";

import Avatar from "/src/components/Display/Avatar";
import { routes } from "/src/routes";
import Error404 from "/src/components/Error404";
import styles from "./AppBar.scss";
import prefix from "/src/common/prefix";
import LogoutButton from "./LogoutButton";

import { Dropdown } from "antd";
import { useAppSelector } from "/src/store/hooks";
import { useImmer } from "use-immer";
import SwitchAccountButton from "./SwitchAccount";

interface State {
  drawerActive: boolean;
  drawerPinned: boolean;
  isLogoutClicked: boolean;
}

function HomeLayout(): JSX.Element {
  const { t } = useTranslation("common");
  const userInfo = useAppSelector((state) => state.auth.user);
  const [state, setState] = useImmer<State>({
    drawerActive: false,
    drawerPinned: true,
    isLogoutClicked: false,
  });

  const handleLogout = () => {
    setState((draft) => {
      draft.isLogoutClicked = true;
    });
  };

  const handleLogoutSuccess = () => {
    setState((draft) => {
      draft.isLogoutClicked = false;
    });
  };

  const toggleDrawerPinned = () => {
    setState((draft) => {
      draft.drawerPinned = !draft.drawerPinned;
    });
  };

  const toggleDrawerActive = () => {
    setState((draft) => {
      draft.drawerActive = !draft.drawerActive;
    });
  };

  const RenderUserProfileMenu = () => {
    return (
      <div>
        <Card style={{ width: 280, height: 140, padding: 0, opacity: 1 }}>
          <CardTitle>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <Avatar name={userInfo?.name} type="text" />
              <div style={{ marginLeft: 13 }}>
                <p style={{ fontWeight: "bold" }}>{userInfo?.name}</p>
                <p style={{ color: "#777" }}>{userInfo?.email}</p>
              </div>
            </div>
          </CardTitle>
          <CardActions style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Link to="/user/profile">
                <Button
                  style={{ pointerEvents: "auto", width: "100%" }}
                  llabel={t("navbar.setting")}
                  icon="settings"
                />
              </Link>
            </div>
            <LogoutButton
              onLogout={handleLogout}
              onLogoutSuccess={handleLogoutSuccess}
            ></LogoutButton>
          </CardActions>
        </Card>
      </div>
    );
  };

  return (
    <LoginStatus>
      <Layout>
        <Switch>
          <Route
            exact
            path="/account/:accountId"
            render={({ match }) => (
              <Redirect to={`/account/${match.params.accountId}/overview`} />
            )}
          />
        </Switch>
        {/*
        AppBar should be a direct descendant of the Layout.
        more details: https://github.com/react-toolbox/react-toolbox/issues/1252#issuecomment-280913751
      */}

        <AppBar fixed leftIcon="menu" onLeftIconClick={toggleDrawerPinned}>
          <Navigation type="horizontal" className={styles.appNav}>
            <div className={styles.appTitle}>
              <NavLink to="/home" style={{ display: "flex" }}>
                <img
                  style={{ width: 32, height: 32, marginRight: "1.4rem" }}
                  alt="App Logo"
                  src={`${prefix.filePrefix}/assets/images/initsoc_logo.png`}
                />
                <p
                  style={{
                    flex: 4,
                    lineHeight: "32px",
                    fontSize: "1.6rem",
                    fontWeight: "bold",
                  }}
                >
                  {t("navbar.title")}
                </p>
              </NavLink>
            </div>
            <SwitchAccountButton />
            <Dropdown overlay={RenderUserProfileMenu()} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {/* <span className="material-icons md-18 md-light" style={{ borderRadius: '50%', backgroundColor: 'gray' }}>person</span> */}
                <IconMenu
                  style={{ borderRadius: "50%", backgroundColor: "gray" }}
                  icon={
                    <span className="material-icons md-18 md-light">
                      person
                    </span>
                  }
                ></IconMenu>
              </a>
            </Dropdown>
          </Navigation>
        </AppBar>

        <Layout>
          <NavDrawer
            clipped
            permanentAt="xxxl"
            active={state.drawerActive}
            pinned={state.drawerPinned}
            onOverlayClick={toggleDrawerActive}
          >
            <Switch>
              <Sidebar></Sidebar>
              <Route component={Error404} />
            </Switch>
          </NavDrawer>
          <Panel bodyScroll={false} style={{ flex: 1, padding: "1.8rem" }}>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.main}
                />
              ))}
              <Route component={Error404} />
            </Switch>
          </Panel>
        </Layout>
      </Layout>
    </LoginStatus>
  );
}

export default HomeLayout;
