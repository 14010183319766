import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "/src/store/store";
import { AutoReply } from "/src/utils/reactQuery/autoReply";

// Define a type for the slice state
interface AutoReplyState {
  selectedRuleId: number | null;
  isEditingRule: boolean;
}

// Define the initial state using that type
const initialState: AutoReplyState = {
  selectedRuleId: null,
  isEditingRule: false,
};

export const autoReplySlice = createSlice({
  name: "autoReply",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedRule: (state, action: PayloadAction<number | null>) => {
      state.selectedRuleId = action.payload;
    },
    removeSelectedRule: (state) => {
      state.selectedRuleId = null;
    },
    setIsEditingRule: (state, action: PayloadAction<boolean>) => {
      state.isEditingRule = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setSelectedRule, setIsEditingRule, removeSelectedRule, reset } =
  autoReplySlice.actions;

export default autoReplySlice.reducer;
