//login API from general-api-gateway
import { loginApi } from "/src/utils/axios";

export const login = (params: LoginPayload) => {
  return loginApi.post<LoginPayload, LoginResponse>("/login", params);
};

export const logout = async () => {
  return await loginApi.post("/logout");
};

export interface LoginPayload {
  email: string;
  password: string;
}

export interface LoginResponse {
  success: boolean;
}
