import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "/src/store/store";
import { Account } from "/src/utils/reactQuery/org";
import type { UserProfileListResponse } from "/src/utils/reactQuery/user";

// Define a type for the slice state
interface AuthState {
  login: boolean;
  lastAccessAccountId: number | null;
  orgId: number | null;
  userId: number | null;
  user: UserProfileListResponse["data"] | null;
  assignedAccounts: number[];
  selectedAccount: Account | null;
}

// Define the initial state using that type
const initialState: AuthState = {
  login: true, // if API return 401, this will change to false
  lastAccessAccountId: null, // also it's the account id currently user has selected
  orgId: null,
  userId: null,
  user: null,
  assignedAccounts: [],
  selectedAccount: null,
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    loggedIn: (state) => {
      state.login = true;
    },
    loggedOut: (state) => {
      state.login = false;
      // clear saved account ID when logged out
      window.localStorage.removeItem("accountId");
      //clear all user data
      state.lastAccessAccountId = null;
      state.user = null;
      state.selectedAccount = null;
      state.orgId = null;
      state.userId = null;
      state.assignedAccounts = [];
    },
    setUser: (
      state,
      action: PayloadAction<UserProfileListResponse["data"]>
    ) => {
      state.user = action.payload;
      state.userId = action.payload.id;
      //TODO need to store to localstorage for selected organization and account later
      // TODO: refactor to better and safer way to match the account and organization
      state.orgId = action.payload.organization_id[0];
      // we need to handle user account selection
      const accountId = window.localStorage.getItem("accountId");

      // check if the value on localstorage exists and valid
      if (
        accountId &&
        accountId.length > 0 &&
        !isNaN(parseInt(accountId, 10))
      ) {
        // localstorage valid

        // search for account
        const account = action.payload.accounts.find(
          (acc) => acc.id === parseInt(accountId, 10)
        );
        if (account) {
          // if account exist
          state.lastAccessAccountId = account.id;
          state.selectedAccount = account;
          // TODO: refactor to better and safer way to match the account and organization
          // state.orgId = account.organization_ids[0];
        } else {
          // account not exist
          if (action.payload.accounts.length > 0) {
            // user has wechat account associated

            // preselect the first account
            window.localStorage.setItem(
              "accountId",
              action.payload.accounts[0].id.toString()
            );
            state.lastAccessAccountId = action.payload.accounts[0].id;
            state.selectedAccount = action.payload.accounts[0];
            // TODO: refactor to better and safer way to match the account and organization
            // state.orgId = action.payload.accounts[0].organization_ids[0];
          } else {
            // user does not have wechat account associated
          }
        }
      } else {
        // if account Id doesn't exist or isValid accountId value
        if (action.payload.accounts.length > 0) {
          // preselect the first account
          window.localStorage.setItem(
            "accountId",
            action.payload.accounts[0].id.toString()
          );
          state.lastAccessAccountId = action.payload.accounts[0].id;
          state.selectedAccount = action.payload.accounts[0];
          // TODO: refactor to better and safer way to match the account and organization
          // state.orgId = action.payload.accounts[0].organization_ids[0];
        }
      }
    },
    selectAccount: (state, action: PayloadAction<Account>) => {
      // save the selected account id into local storage
      window.localStorage.setItem("accountId", action.payload.id.toString());

      // refresh and bring user to home page
      window.location.replace(
        `${window.location.protocol}//${window.location.host}`
      );
    },
  },
});

export const { loggedIn, loggedOut, setUser, selectAccount } =
  authSlice.actions;

export default authSlice.reducer;
