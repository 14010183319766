import { userApi } from "../axios";
import { useQuery, UseQueryOptions, QueryKey } from "react-query";
import { ResponseData } from "/src/types/api/ApiTypeHelper";
import { AxiosResponse } from "axios";
export type UserProfileListResponse = ResponseData<typeof userApi.profileList>;

export const useGetUserProfileList = (options?: { enabled?: boolean }) => {
  return useQuery(
    ["get-UserProfileList"],
    () => userApi.profileList(),
    options
  );
};
