import moment from "moment";
import React from "react";
import i18n from "/src/i18n/index.js";

const isEmpty = (value) =>
  value === undefined || value === null || value === "";
const join = (rules) => (value, data) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export function email(value) {
  // Let's not start a debate on email regex. This is just for an example app!
  if (
    !isEmpty(value) &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ) {
    // return 'Invalid email address';
    return i18n.t("error:invalid", { field: i18n.t("error:email") });
  }
}

export function url(value) {
  // The following regex is taken form validation.js from another lib
  const urlRegex =
    "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]" +
    "\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]" +
    "\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)" +
    "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]" +
    "{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
  const pattern = new RegExp(urlRegex, "i");
  if (
    !isEmpty(value) &&
    !(value && value.length < 2083 && pattern.test(value))
  ) {
    // return 'Invalid URL';
    return i18n.t("error:invalid", { field: i18n.t("error:url") });
  }
}

export function required(value) {
  if (Array.isArray(value)) {
    if (!value.length) {
      return i18n.t("error:required");
    }
  } else if (isEmpty(value)) {
    return i18n.t("error:required");
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      // return `Must be at least ${min} characters`;
      return i18n.t("error:min_length", { length: min });
    }
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      // return `Must be no more than ${max} characters`;
      return i18n.t("error:max_length", { length: max });
    }
  };
}

export function maxStringSize(max) {
  return (value) => {
    function getStringMemorySize(_string) {
      let codePoint;
      let accum = 0;
      for (
        let stringIndex = 0, endOfString = _string.length;
        stringIndex < endOfString;
        stringIndex++
      ) {
        codePoint = _string.charCodeAt(stringIndex);
        if (codePoint < 0x100) {
          accum += 1;
        } else if (codePoint < 0x10000) {
          accum += 2;
        } else if (codePoint < 0x1000000) {
          accum += 3;
        } else {
          accum += 4;
        }
      }
      return accum * 2;
    }
    // const chineseRegex = '^[\u4E00-\u9FA5\uF900-\uFA2D]+$';
    if (!isEmpty(value) && getStringMemorySize(value) > max) {
      // return `Must be no more than ${max / 4} characters or ${max / 2} letters`;
      return i18n.t("error:max_string_size", { length: max / 4, num: max / 2 });
    }
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    // return 'Must be an integer';
    return i18n.t("error:type_check", { type: i18n.t("error:integer") });
  }
}

export function boolean(value) {
  if (typeof value !== "boolean") {
    // return 'Must be a boolean';
    return i18n.t("error:type_check", { type: i18n.t("error:boolean") });
  }
}

export function datetime(format) {
  return (value) => {
    if (!moment(value, format).isValid()) {
      // return 'Invalid datetime';
      return i18n.t("error:invalid", { field: i18n.t("error:datetime") });
    }
  };
}

export function timestamp() {
  return (value) => {
    if (!moment.unix(value).isValid()) {
      // return 'Invalid datetime';
      return i18n.t("error:invalid", { field: i18n.t("error:datetime") });
    }
  };
}

export function oneOf(enumeration) {
  return (value) => {
    if (!~enumeration.indexOf(value)) {
      // return `Must be one of: ${enumeration.join(', ')}`;
      return i18n.t("error:one_of", { field: enumeration.join(", ") });
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        // return 'Do not match';
        return i18n.t("error:match");
      }
    }
  };
}

export function matchValue(matchVal, callback) {
  return (value, data) => {
    if (value === matchVal) {
      return callback(value, data);
    }
  };
}

export function exist(field, callback) {
  return (value, data) => {
    if (data) {
      if (data[field]) {
        return callback(value, data);
      }
    }
  };
}

export function requireField(field) {
  return (value, data) => {
    if (data) {
      if (Array.isArray(data[field])) {
        if (!data[field].length) {
          return { _error: i18n.t("error:required") };
        }
      } else if (isEmpty(data[field])) {
        // return 'Required';
        return i18n.t("error:required");
      }
    }
  };
}

export function greaterThanNow(
  format,
  now = moment().seconds(0).milliseconds(0)
) {
  return (value) => {
    let a = null;
    if (format) {
      a = moment(value, format);
    } else {
      a = moment.unix(value);
    }
    if (now.diff(a, "minutes") > 0) {
      // return 'Must be greater than current time';
      return i18n.t("error:greater_than_time", {
        time: i18n.t("error:current_time"),
      });
    }
  };
}

export function greaterThanTime(field, format, label) {
  return (value, data) => {
    if (data) {
      const a =
        format === "timestamp" ? moment.unix(value) : moment(value, format);
      const b =
        format === "timestamp"
          ? moment.unix(data[field])
          : moment(data[field], format);
      if (b.diff(a) >= 0) {
        // return `Must be greater than ${label}`;
        return i18n.t("error:greater_than_time", { time: label });
      }
    }
  };
}

export function smallerThanTime(field, format, label) {
  return (value, data) => {
    if (data) {
      const a =
        format === "timestamp" ? moment.unix(value) : moment(value, format);
      const b =
        format === "timestamp"
          ? moment.unix(data[field])
          : moment(data[field], format);
      if (b.diff(a) <= 0) {
        // return `Must be smaller than ${label}`;
        return i18n.t("error:smaller_than_time", { time: label });
      }
    }
  };
}

export function matchArray(array) {
  return (value) => {
    if (!array.includes(value)) {
      // return 'Required';
      return i18n.t("error:required");
    }
  };
}

export function matchOtherField(field, fieldValue, callback) {
  return (value, data) => {
    if (data) {
      // check fieldValue is array or not
      if (Array.isArray(fieldValue)) {
        if (fieldValue.includes(data[field])) {
          return callback(value, data);
        }
      } else if (data[field] === fieldValue) {
        return callback(value, data);
      }
    }
  };
}

export function matchFileExtension(type) {
  return (value) => {
    // check input is file or not
    if (value && typeof value === "object") {
      let validExtensions = [];
      let media;
      switch (type) {
        case "image":
          media = type;
          validExtensions = ["bmp", "jpg", "jpeg", "png", "gif"];
          break;
        case "video":
          media = type;
          validExtensions = ["mp4"];
          break;
        case "audio":
          media = type;
          validExtensions = ["mp3", "wav"];
          break;
        case "thumb":
          media = "image";
          validExtensions = ["jpg", "jpeg"];
          break;
        default:
          return "Invalid valid type";
      }
      const validTypes = validExtensions.map((ext) => `${media}/${ext}`);
      if (!validTypes.includes(value.type)) {
        // return `Only accept file type in ${validExtensions.join()} format.`;
        return i18n.t("error:file_extension", {
          format: validExtensions.join(),
        });
      }
    }
  };
}

function formatBytes(size, decimals) {
  if (size === 0) {
    return { maxSize: 0, unit: "Bytes" };
  }
  const base = 1e3;
  const dm = decimals || 2;
  const units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const index = Math.floor(Math.log(size) / Math.log(base));
  return {
    maxSize: parseFloat(size / base ** index).toFixed(dm),
    unit: units[index],
  };
}

export function maxFileSize(max) {
  return (value) => {
    if (value && typeof value === "object") {
      const { maxSize, unit } = formatBytes(max);
      if (value.size > max) {
        // return `File must be smaller than ${maxSize} ${unit}`;
        return i18n.t("error:max_file_size", { max: maxSize, unit });
      }
    }
  };
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export function confirm(field) {
  return (value, data) => {
    if (data) {
      if (data[field] !== value) {
        // return 'Confirmation fail.';
        return i18n.t("error:confirm");
      }
    }
  };
}

export function fieldValueEqual(field, fieldValue, callback) {
  return (value, data) => {
    if (data && data[field] === fieldValue) {
      return callback(value, data);
    }
  };
}
