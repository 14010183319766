.divider {
  background-color: rgb(238, 238, 238);
  border: 0;
  height: 1px;
  margin: -1px 0 0
}
.divider.inset {
    margin-left: 72px;
    margin-right: 16px;
  }
.list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 8px 0;
  position: relative;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%
}
.list *,
    .list *::after,
    .list *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.list + .divider {
    margin-top: -8px;
  }
.subheader {
  color: rgb(117, 117, 117);
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  margin: -8px 0 0;
  padding-left: 16px;
}
.listItem {
  position: relative
}
.listItem > [data-react-toolbox='ripple'] {
    overflow: hidden;
  }
.listItem .ripple {
    color: rgb(117, 117, 117);
  }
.listItem ~ .divider {
    margin-bottom: 8px;
    margin-top: 8px;
  }
.checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  margin: 0;
  min-height: 48px;
  width: 100%
}
.checkbox > [data-react-toolbox='check'] {
    margin-right: 38px;
  }
.checkbox > [data-react-toolbox='label'] {
    padding-left: 0;
  }
.item {
  align-items: center;
  color: rgb(33, 33, 33);
  display: flex;
  min-height: 48px;
  padding: 0 16px;
  position: relative;
  transition-duration: 0.28s;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.item.selectable:not(.disabled):hover {
    background-color: rgb(238, 238, 238);
    cursor: pointer;
  }
.item.disabled {
    pointer-events: none
  }
.item.disabled:not(.checkboxItem) {
      opacity: 0.5;
    }
.item.disabled > .checkbox > [data-react-toolbox='label'] {
      opacity: 0.5;
    }
.itemAction {
  display: flex;
  margin: 8px 16px 8px 0
}
.itemAction > :not(button) {
    padding: 0;
  }
.itemAction > [data-react-toolbox='font-icon'] {
    color: rgb(117, 117, 117);
    font-size: 24px;
  }
.left [data-react-toolbox='font-icon'] {
    width: 18px;
  }
.left :last-child > [data-react-toolbox='font-icon'] {
      margin-right: 22px;
    }
.right > :last-child {
    margin-right: 0;
  }
.right > :first-child {
    margin-left: 16px;
  }
.left,
.right {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
}
.itemContentRoot {
  display: block;
  flex-grow: 1
}
.itemContentRoot.large {
    display: flex;
    flex-direction: column;
    height: 72px;
    justify-content: center;
  }
.itemText {
  display: block
}
.itemText:not(.primary) {
    color: rgb(117, 117, 117);
    font-size: 14px;
    padding-top: 3px;
    white-space: normal;
  }
.itemText.primary {
    color: rgb(33, 33, 33);
    font-size: 16px;
  }
