.suggestions {
  background-color: rgb(255, 255, 255);
  list-style: none;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  transition-duration: 0.35s;
  transition-property: max-height, box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  width: 100%;
  z-index: 100
}
.suggestions::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
.suggestions:not(.up) {
    margin-top: -20px;
  }
.suggestions.up {
    bottom: 0;
  }
.autocomplete {
  padding: 10px 0;
  position: relative;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%
}
.autocomplete *,
    .autocomplete *::after,
    .autocomplete *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.autocomplete.focus .suggestions {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
      max-height: 45vh;
      -ms-overflow-style: none;
      visibility: visible;
    }
.values {
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0 0 5px 0;
}
.value {
  margin: 2.5px 5px 2.5px 0;
}
.suggestion {
  cursor: pointer;
  font-size: 16px;
  padding: 10px
}
.suggestion.active {
    background-color: rgb(238, 238, 238);
  }
.input {
  position: relative
}
.input::after {
    border-left: 5.48571px solid transparent;
    border-right: 5.48571px solid transparent;
    border-top: 5.48571px solid rgba(33, 33, 33, 0.12);
    content: '';
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 8px;
    top: 50%;
    transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    width: 0;
  }
