import React, { useMemo } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Button, Card, CardTitle, Snackbar } from "react-toolbox";

import MemberAcceptForm from "/src/components/Member/AcceptForm";
import styles from "./Invite.scss";

import { Link, useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import { useMutation } from "react-query";
import { inviteApi } from "/src/utils/axios";
import { AcceptCreatePayload } from "/src/types/api/data-contracts";
import { ApiError } from "/src/types/api/ApiErrorType";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function OrgMemberInvite(): JSX.Element {
  const { t } = useTranslation("member");
  const query = useQuery();
  const email = query.get("email");
  const token = query.get("token");

  const [state, setState] = useImmer({
    isSubmitted: false,
    isSubmitting: false,
    isSnackbarActive: false,
    snackbarLabel: "",
  });

  const handleSnackbarClick = () => {
    hideSnackbar();
  };

  const handleSnackbarTimeout = () => {
    hideSnackbar();
  };

  const hideSnackbar = () => {
    setState((draft) => {
      draft.isSnackbarActive = false;
    });
  };

  const handleMemberAccept = useMutation(
    (data: AcceptCreatePayload) => {
      if (!email || !token) {
        throw new Error("email or token is missing on this page!");
      }
      return inviteApi.acceptCreate({
        ...data,
        email,
        token,
      });
    },
    {
      onMutate: () => {
        setState((draft) => {
          draft.isSubmitting = true;
          draft.isSubmitted = false;
        });
      },
      onSuccess: () => {
        handleAcceptSuccess();
      },
      onError: (error: ApiError) => {
        handleAcceptFail(error);
      },
    }
  );

  const handleAcceptSuccess = () => {
    setState((draft) => {
      draft.isSubmitting = false;
      draft.isSubmitted = true;
    });
  };

  const handleAcceptFail = (error: ApiError) => {
    setState((draft) => {
      draft.isSubmitting = false;
      draft.isSubmitted = false;
    });

    const snackbarHint = `Error: ${error.status} ${error.message}`;
    return handleSnackbarShow(snackbarHint);
  };

  const handleSnackbarShow = (hint: string) => {
    setState((draft) => {
      draft.snackbarLabel = hint;
      draft.isSnackbarActive = true;
    });
  };

  return (
    <div className={styles.container}>
      {/* <Helmet
        title={config('htmlPage.titleTemplate').replace('%s', t('accept_invitation_form.header'))}
      /> */}
      {token === null && (
        <div className={styles.messageContainer}>
          <p>{t("accept_invitation_form.message.missing_token_error")}</p>
        </div>
      )}
      {email === null && (
        <div className={styles.messageContainer}>
          <p>{t("accept_invitation_form.message.missing_email_error")}</p>
        </div>
      )}
      {!state.isSubmitted && email && token && (
        <div>
          <Card style={{ width: "350px", padding: "2rem" }}>
            <CardTitle
              avatar="/assets/images/initsoc_logo.png"
              title={t("accept_invitation_form.header")}
            />
            <p>{t("accept_invitation_form.email")}</p>
            <p className={styles.value}>{email}</p>
            <MemberAcceptForm
              onSubmit={(data) => handleMemberAccept.mutate(data)}
              isFormSubmitting={state.isSubmitting}
            />
          </Card>
        </div>
      )}
      {state.isSubmitted && (
        <div className={styles.messageContainer}>
          <p>{t("accept_invitation_form.message.accept_success")}</p>
          <Link to="/auth/login" replace>
            <Button
              label={t("accept_invitation_form.action.sign_in")}
              primary
              raised
            />
          </Link>
        </div>
      )}
      <Snackbar
        action={t("accept_invitation_form.snackbar_dismiss")}
        active={state.isSnackbarActive}
        label={state.snackbarLabel}
        timeout={12000}
        onClick={handleSnackbarClick}
        onTimeout={handleSnackbarTimeout}
        type="cancel"
      />
    </div>
  );
}

export default OrgMemberInvite;
