.container {
  height: 32px;
  margin-right: 32px;
  position: relative;
  -ms-user-select: none;
      user-select: none;
  width: calc(100% - 32px);
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%
}
.container *,
    .container *::after,
    .container *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.container:not(:last-child) {
    margin-right: 42px;
  }
.container:not(:first-child) {
    margin-left: 10px;
  }
.knob {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  left: 0;
  position: relative;
  top: 0;
  width: 32px;
  z-index: 200;
}
.innerknob {
  background-color: rgb(63, 81, 181);
  border-radius: 50%;
  height: 12px;
  transition-duration: 0.1s;
  transition-property: height, width, background-color, border;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 12px;
  z-index: 100;
}
.snaps {
  display: flex;
  flex-direction: row;
  height: 2px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 15px;
  width: calc(100% + 2px)
}
.snaps::after {
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    content: '';
    display: block;
    height: 2px;
    width: 2px;
  }
.snap {
  flex: 1
}
.snap::after {
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    content: '';
    display: block;
    height: 2px;
    width: 2px;
  }
.input {
  margin-bottom: 0;
  padding: 0;
  width: 50px
}
.input > input {
    text-align: center;
  }
.progress {
  height: 100%;
  left: 16px;
  position: absolute;
  top: 0;
  width: 100%
}
.progress .innerprogress {
    height: 2px;
    position: absolute;
    top: 15px
  }
.progress .innerprogress [data-ref='value'] {
      transition-duration: 0s;
    }
.slider:focus .knob::before {
    background-color: rgb(63, 81, 181);
    border-radius: 50%;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.26;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
.slider[disabled] {
    cursor: auto;
    pointer-events: none
  }
.slider[disabled] .innerknob {
      background-color: rgba(177, 177, 177, 1);
    }
.slider.editable {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
.slider.pinned .innerknob::before {
      background-color: rgb(63, 81, 181);
      border-radius: 50% 50% 50% 0;
      content: '';
      height: 26px;
      left: 0;
      margin-left: 3px;
      position: absolute;
      top: 0;
      transform: rotate(-45deg) scale(0) translate(0);
      transition:
        transform 0.2s ease,
        background-color 0.18s ease;
      width: 26px;
    }
.slider.pinned .innerknob::after {
      color: rgb(255, 255, 255);
      content: attr(data-value);
      font-size: 10px;
      height: 26px;
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      transform: scale(0) translate(0);
      transition:
        transform 0.2s ease,
        background-color 0.18s ease;
      width: 32px;
    }
.slider.ring .innerknob {
      background-color: transparent;
      border: 2px solid rgb(238, 238, 238)
    }
.slider.ring .innerknob::before {
        background-color: rgb(63, 81, 181);
      }
.slider.ring .progress {
      left: 20px;
      transition:
        left 0.18s ease,
        width 0.18s ease;
      width: calc(100% - 4px);
    }
.slider.ring.pinned .innerknob {
        background-color: rgb(255, 255, 255);
      }
.slider.ring.pinned .progress {
        left: 16px;
        width: 100%;
      }
.slider.pressed.pinned .innerknob::before {
        transform: rotate(-45deg) scale(1) translate(17px, -17px);
        transition-delay: 100ms;
      }
.slider.pressed.pinned .innerknob::after {
        transform: scale(1) translate(0, -17px);
        transition-delay: 100ms;
      }
.slider.pressed:not(.pinned).ring .progress {
        left: 30px;
        width: calc(100% - 14px);
      }
.slider.pressed:not(.pinned) .innerknob {
        height: 100%;
        transform: translateZ(0);
        width: 100%;
      }
