.radio {
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  position: relative;
  vertical-align: top;
  width: 20px;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%
}
.radio *,
    .radio *::after,
    .radio *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.radio::before {
    background-color: rgb(63, 81, 181);
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(0);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
  }
.radio .ripple {
    background-color: rgb(63, 81, 181);
    opacity: 0.3;
    transition-duration: 650ms;
  }
.radioChecked {
  border: 2px solid rgb(63, 81, 181);
  composes: radio
}
.radioChecked::before {
    transform: scale(0.65);
  }
.field {
  display: block;
  height: 20px;
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.text {
  color: rgb(0, 0, 0);
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
  vertical-align: top;
  white-space: nowrap;
}
.disabled {
  composes: field
}
.disabled .text {
    color: rgba(0, 0, 0, 0.26);
  }
.disabled .radio {
    border-color: rgba(0, 0, 0, 0.26);
    cursor: auto;
  }
.disabled .radioChecked {
    border-color: rgba(0, 0, 0, 0.26);
    cursor: auto
  }
.disabled .radioChecked::before {
      background-color: rgba(0, 0, 0, 0.26);
    }
.input {
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  height: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  width: 0
}
.input:focus ~ .radio {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  }
.input:focus ~ .radioChecked {
    box-shadow: 0 0 0 10px rgba(63, 81, 181, 0.26);
  }
