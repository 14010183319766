import { api, orgApi } from "../axios";
import { useQuery, useInfiniteQuery } from "react-query";
import type { UseQueryOptions } from "react-query";
import type {
  AccountDetailParams,
  AccountOrgMemberDetailParams,
  MemberDetailParams,
} from "/src/types/api/data-contracts";
import type { ResponseData } from "/src/types/api/ApiTypeHelper";
import { handleNextPageParam, mapPaginatedData } from "./index";
import { useImmer } from "use-immer";
import { useEffect } from "react";

export type Account = ResponseData<typeof orgApi.accountDetail>["data"][0];
export type Member = ResponseData<
  typeof orgApi.accountOrgMemberDetail
>["data"][0];
export type Organization = ResponseData<typeof orgApi.getOrg>["data"];

export const useGetOrgDetail = (
  orgId: number,
  config?: { enabled?: boolean }
) => {
  const query = useQuery(
    ["get-OrgAccountDetail", orgId],
    () => orgApi.getOrg(orgId),
    { ...config }
  );

  return { orgDetail: query.data?.data, ...query };
};

export const useGetOrgAccountList = (
  orgId: number,
  params?: Omit<AccountDetailParams, "orgId">,
  config?: { enabled?: boolean }
) => {
  const [accountList, setAccountList] = useImmer<Map<number, Account>>(
    new Map()
  );
  const query = useInfiniteQuery(
    ["get-OrgAccountList", orgId, params],
    ({ pageParam = 1 }) =>
      orgApi.accountDetail({
        orgId,
        count: 10,
        ...{ page: pageParam },
        ...params,
      }),
    {
      getNextPageParam: handleNextPageParam,
      onSuccess: (data) => {
        setAccountList(mapPaginatedData(data));
      },
      ...config,
    }
  );

  return { accountList, ...query };
};

export const useGetOrgMemberList = (
  orgId: number,
  params?: Omit<MemberDetailParams, "orgId">,
  config?: { enabled?: boolean }
) => {
  const [memberList, setMemberList] = useImmer<Map<number, Member>>(new Map());
  const query = useInfiniteQuery(
    ["get-OrgMemberList", orgId, params],
    ({ pageParam = 1 }) =>
      orgApi.memberDetail({
        orgId,
        count: 10,
        ...{ page: pageParam },
        ...params,
      }),
    {
      getNextPageParam: handleNextPageParam,
      onSuccess: (data) => {
        setMemberList(mapPaginatedData(data));
      },
      ...config,
    }
  );

  return { memberList, ...query };
};

export const useGetOrgAccountDetail = (
  orgId: number,
  orgAccId: number,
  config?: { enabled?: boolean }
) => {
  const query = useQuery(
    ["get-OrgAccountDetail", orgId, orgAccId],
    () => orgApi.accountDetail2(orgId, orgAccId),
    { ...config }
  );

  return { accountDetail: query.data?.data, ...query };
};

export const useGetMemberListOfOrgAccount = (
  orgId: number,
  orgAccId: number,
  params?: Omit<AccountOrgMemberDetailParams, "orgId" | "accId">,
  config?: { enabled?: boolean }
) => {
  const query = useQuery(
    ["get-OrgMemberOfAccount", orgId, orgAccId, params],
    ({ pageParam = 1 }) =>
      orgApi.accountOrgMemberDetail({
        orgId,
        accId: orgAccId,
        ...{ page: pageParam },
        ...params,
      }),
    {
      ...config,
    }
  );
  return { memberList: query.data?.data.data, ...query };
};
