import * as React from "react";

import {
  List as HomeAccountList,
  Create as HomeAccountCreate,
} from "/src/containers/Home/Account";

import OrgAccountIndex from "/src/containers/Organization/Account";
import { Index as OrgMemberIndex } from "/src/containers/Organization/Member";
import OrgProfileIndex from "/src/containers/Organization/Profile";

import UserProfile from "/src/containers/Home/User";

import AccountProfile from "/src/containers/Account/Profile";

import AccountAnalyticOverview from "/src/containers/Account/Analytic/Overview";
import {
  Index as AccountAnalyticBroadcastIndex,
  Detail as AccountAnalyticBroadcastDetail,
} from "/src/containers/Account/Analytic/Broadcast";
import AccountAnalyticFollower from "/src/containers/Account/Analytic/Follower";
import AccountAnalyticArticle from "/src/containers/Account/Analytic/Article";
import AccountAnalyticMenu from "/src/containers/Account/Analytic/Menu";
import AccountAnalyticMessage from "/src/containers/Account/Analytic/Message";

import AccountFollower from "/src/containers/Account/Follower";

import AccountInbox from "/src/containers/Account/Inbox";

import {
  Index as AccountBroadcastIndex,
  Create as AccountBroadcastCreate,
} from "/src/containers/Account/Broadcast";

import {
  Index as AccountArticleIndex,
  Create as AccountArticleCreate,
  Detail as AccountArticleDetail,
} from "/src/containers/Account/Article";

import AccountMaterial from "/src/containers/Account/Material";

import AccountMenu from "/src/containers/Account/Menu";
import AccountScheduleMenu from "/src/containers/Account/ScheduleMenu";
import { useParams } from "react-router-dom";

import {
  Index as AccountAutoReplyIndex,
  Create as AccountAutoReplyCreate,
} from "/src/containers/Account/AutoReply";

// List out all possible route param keys in this interface so it could be used
// for useParams react hooks.
export interface RouteParams {
  organizationId?: string;
  accountId?: string;
  step?: string;
}

// add type to the useParams hook.
export const useTypedRouteParams = () => {
  return useParams<RouteParams>();
};

export const routes = [
  // Homepage routes
  {
    path: "/home",
    exact: true,
    main: HomeAccountList,
  },
  {
    path: "/home/account",
    exact: true,
    main: HomeAccountList,
  },
  {
    path: "/home/account/create",
    exact: true,
    main: HomeAccountCreate,
  },

  // Organization routes
  {
    path: "/org/:organizationId/account",
    exact: true,
    main: OrgAccountIndex,
  },
  {
    path: "/org/:organizationId/member",
    exact: true,
    main: OrgMemberIndex,
  },
  {
    path: "/org/:organizationId/profile",
    exact: true,
    main: OrgProfileIndex,
  },

  // User Profile
  {
    path: "/user/profile",
    exact: true,
    main: UserProfile,
  },

  // Account routes
  // TODO: deprecated, to be removed (duplicated with /org/{org_id}/account page)
  // {
  //   path: "/org/:organizationId/account/:accountId/settings",
  //   exact: true,
  //   main: AccountProfile,
  // },

  /* Account Analyze Modules */
  {
    path: "/org/:organizationId/account/:accountId/overview",
    exact: true,
    main: AccountAnalyticOverview,
  },
  {
    path: "/org/:organizationId/account/:accountId/overview/broadcast",
    exact: true,
    main: AccountAnalyticBroadcastIndex,
  },
  {
    path: "/org/:organizationId/account/:accountId/overview/broadcast/:msgId",
    exact: true,
    main: AccountAnalyticBroadcastDetail,
  },
  {
    path: "/org/:organizationId/account/:accountId/overview/follower",
    exact: true,
    main: AccountAnalyticFollower,
  },
  {
    path: "/org/:organizationId/account/:accountId/overview/article",
    exact: true,
    main: AccountAnalyticArticle,
  },
  {
    path: "/org/:organizationId/account/:accountId/overview/message",
    exact: true,
    main: AccountAnalyticMessage,
  },
  {
    path: "/org/:organizationId/account/:accountId/overview/menu",
    exact: true,
    main: AccountAnalyticMenu,
  },

  /* Account Follower Module  */
  {
    path: "/org/:organizationId/account/:accountId/follower",
    exact: true,
    main: AccountFollower,
  },

  /* Account Inbox Module */
  {
    path: "/org/:organizationId/account/:accountId/inbox",
    exact: true,
    main: AccountInbox,
  },

  /* Account Broadcast Module */
  {
    path: "/org/:organizationId/account/:accountId/broadcast",
    exact: true,
    main: AccountBroadcastIndex,
  },
  {
    path: "/org/:organizationId/account/:accountId/broadcast/create",
    exact: true,
    main: AccountBroadcastCreate,
  },

  /* Account Article Module */
  {
    path: "/org/:organizationId/account/:accountId/article",
    exact: true,
    main: AccountArticleIndex,
  },
  {
    path: "/org/:organizationId/account/:accountId/article/create",
    exact: true,
    main: AccountArticleCreate,
  },
  {
    path: "/org/:organizationId/account/:accountId/article/:articleId",
    exact: true,
    main: AccountArticleDetail,
  },

  {
    path: "/org/:organizationId/account/:accountId/material",
    exact: true,
    main: AccountMaterial,
  },
  /* Account Menu */
  {
    path: "/org/:organizationId/account/:accountId/menu",
    exact: true,
    main: AccountMenu,
  },
  {
    path: "/org/:organizationId/account/:accountId/schedule-menu",
    exact: true,
    main: AccountScheduleMenu,
  },

  /* Account AutoReply */
  {
    path: "/org/:organizationId/account/:accountId/auto-reply",
    exact: true,
    main: AccountAutoReplyIndex,
  },
  {
    path: "/org/:organizationId/account/:accountId/auto-reply/create",
    exact: true,
    main: AccountAutoReplyCreate,
  },

  {
    path: "/org/:organizationId/account/:accountId/qrcode",
    exact: true,
    main: React.memo(function Qrcode() {
      return <h5>qrcode</h5>;
    }),
  },
  {
    path: "/org/:organizationId/account/:accountId/store-locator",
    exact: true,
    main: React.memo(function StoreLocator() {
      return <h5>tore locator</h5>;
    }),
  },
];
