.rippleWrapper {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.ripple {
  background-color: currentColor;
  border-radius: 50%;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  transition-duration: 800ms;
  z-index: 100
}
.ripple.rippleRestarting {
    opacity: 0.3;
    transition-property: none;
  }
.ripple.rippleActive {
    opacity: 0.3;
    transition-property: transform;
  }
.ripple:not(.rippleActive):not(.rippleRestarting) {
    opacity: 0;
    transition-property: opacity, transform;
  }
