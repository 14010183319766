/* Orientation */
/* Devices (defined by max width) */
/* Devices (defined by min-width) */
.appBar {
  background: rgb(48, 63, 159);
  color: rgb(255, 255, 255);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  height: 64px;
  padding: 0 24px;
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%
}
.appBar *,
    .appBar *::after,
    .appBar *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.appBar.scrollHide {
    transform: translateY(-100%);
  }
.appBar:not(.flat) {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
.appBar.fixed {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 300;
  }
.appBar .inner {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;
  }
.appBar a {
    color: rgb(255, 255, 255);
  }
@media screen and (max-width: 480px) and (orientation: portrait) {
.appBar {
    height: 56px
}
  }
@media screen and (max-width: 600px) and (orientation: landscape) {
.appBar {
    height: 48px
}
  }
.title {
  flex-grow: 1;
  font-size: 20px;
  font-weight: 500;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.leftIcon {
  margin-left: -12px;
}
.rightIcon {
  margin-left: auto;
  margin-right: -12px;
}
