export enum roleIds {
  system_admin = 1,
  organization_admin = 2,
  organization_manager = 3,
  organization_member = 4,
  account_admin = 5,
  account_editor = 6,
  account_analyst = 7,
  account_guest = 8,
}

export enum orgRoles {
  ORG_ADMIN = "organization_admin",
  ORG_MANAGER = "organization_manager",
  ORG_MEMBER = "organization_member",
}

export enum accRoles {
  ACC_ADMIN = "account_admin",
  ACC_ANALYST = "account_analyst",
  ACC_EDITOR = "account_editor",
}
