import { useQuery, UseQueryOptions, QueryKey } from "react-query";
import { api, orgApi } from "../axios";

import {
  AccountDetailParams,
  MemberDetailParams,
} from "/src/types/api/data-contracts";
import { AxiosResponse } from "axios";

interface PaginatedQueryStruct<T> {
  // this is the data structure which first wrapped by react-query infinity query result,
  // then axios response, then the API response
  pages?: {
    data: {
      data: T[];
    };
  }[];
}

/*
  Note: for react-query options, temp use Pick to extract the needed options from UseQueryOptions.
*/

interface ResponseMeta extends Record<string, unknown> {
  meta: {
    current_page: number | null;
    last_page: number | null;
  };
}

export const useGetOrgDetail = (orgId: number) => {
  return useQuery(["get-OrgDetail", orgId], () => orgApi.getOrg(orgId));
};

//TODO: need to wait backend to update API doc to enable query params
export const useGetAccountList = (
  orgId: number,
  params?: Omit<AccountDetailParams, "orgId">,
  config?: UseQueryOptions
) => {
  return useQuery({
    queryKey: ["get-OrgAccountList", orgId, params],
    queryFn: () => orgApi.accountDetail({ orgId: orgId, ...params }),
    ...config,
  });
};

export const useGetOrgAccountDetail = (orgId: number, orgAccId: number) => {
  return useQuery(["get-OrgAccountDetail", orgId, orgAccId], () =>
    orgApi.accountDetail2(orgId, orgAccId)
  );
};

//TODO: need to wait backend to update API doc to enable query params
export const useGetMemberList = (
  orgId: number,
  params?: Omit<MemberDetailParams, "orgId">,
  config?: Pick<UseQueryOptions<QueryKey>, "enabled">
) => {
  return useQuery(
    ["get-OrgMemberList", orgId, params],
    () => orgApi.memberDetail({ orgId: orgId, ...params }),
    { ...config }
  );
};

/**
 * this method is for useInfiniteQuery's getNextPageParam check
 * which checks the meta object which usually available on our paginated response
 * if there's no more page, it will return undefined
 * if there's more page available, it will return the next page number
 */
export const handleNextPageParam = (
  lastPage: AxiosResponse<ResponseMeta>,
  pages: AxiosResponse<ResponseMeta>[]
): number | undefined => {
  if (lastPage.data.meta.current_page !== lastPage.data.meta.last_page) {
    return lastPage.data.meta.current_page
      ? lastPage.data.meta.current_page + 1
      : 1;
  }
};
/**
 * Genetrate a map from react-query and laravel typical paginated data structure
 */
export const mapPaginatedData = <
  T extends Record<string, unknown>,
  DataType extends PaginatedQueryStruct<T> = PaginatedQueryStruct<T>
>(
  data: DataType,
  key = "id"
): Map<number, T> => {
  const newMap: Map<number, T> = new Map();
  data?.pages?.forEach((page) => {
    page.data.data.forEach((element) => {
      newMap.set(element[key] as number, element);
    });
  });
  return newMap;
};
