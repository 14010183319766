import * as React from "react";
import { Suspense } from "react";
import { App } from "../App";
import {
  BrowserRouter,
  HashRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import Helmet from "react-helmet";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { enableMapSet } from "immer";
import i18n from "/src/i18n/index.js";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
//components
import Login from "/src/containers/Auth/Login/index";
import ResetPassword from "/src/containers/Auth/ResetPassword/index";
import ForgetPassword from "/src/containers/Auth/ForgetPassword/ForgetPassword";
import OrgMemberInvite from "/src/containers/Organization/Member/Invite";
import Layout from "/src/containers/Layout/Home/Layout";
import Error404 from "/src/components/Error404";
import { routes } from "/src/routes";

import "react-flexbox-grid/dist/react-flexbox-grid.css";
import styles from "./styles.scss";
import ApiClient from "../utils/apiClient.js";
import "css/globals.css";
import "css/fontawesome.css";
import "css/react-bootstrap-table.css";
import theme from "/src/utils/reactToolbox.js";
import { ThemeProvider } from "react-css-themr";
import "antd/dist/antd.variable.min.css";
import "./styles.css";
import prefix from "/src/common/prefix";
import { store } from "/src/store/store";
import { ConfigProvider } from "antd";

//antd theme config
//@see https://ant.design/docs/react/customize-theme-variable
// we can't use less version which should be better since parcel doesn't support less's enableJavascript setting well
ConfigProvider.config({
  theme: {
    primaryColor: "#303f9f",
  },
});

//enable Map and Set data structure support for immer
enableMapSet();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //retry 3 times if fail occurred
      retry: 3,
      //disable auto refetch
      refetchIntervalInBackground: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

export interface IMainProps {
  app: App;
}

function Router(props: { children: JSX.Element }) {
  if (window.location.hostname === "localhost") {
    return <HashRouter>{props.children}</HashRouter>;
  } else {
    return <BrowserRouter>{props.children}</BrowserRouter>;
  }
}
export class Main extends React.Component<IMainProps, Record<string, unknown>> {
  public render(): JSX.Element {
    return (
      <Router>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ConfigProvider>
              <Suspense fallback={<div>Loading...</div>}>
                <I18nextProvider i18n={i18n}>
                  <Helmet>
                    <html lang="en" />
                    <meta charSet="utf-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1"
                    />
                    <meta name="msapplication-TileColor" content="#2b2b2b" />
                    <meta
                      name="msapplication-TileImage"
                      content={`${prefix.filePrefix}/favicons/mstile-144x144.png`}
                    />
                    <meta name="theme-color" content="#2b2b2b" />
                    <link
                      rel="apple-touch-icon-precomposed"
                      sizes="152x152"
                      href={`${prefix.filePrefix}/favicons/apple-touch-icon-152x152.png`}
                    />
                    <link
                      rel="apple-touch-icon-precomposed"
                      sizes="144x144"
                      href={`${prefix.filePrefix}/favicons/apple-touch-icon-144x144.png`}
                    />
                    <link
                      rel="apple-touch-icon-precomposed"
                      sizes="120x120"
                      href={`${prefix.filePrefix}/favicons/apple-touch-icon-120x120.png`}
                    />
                    <link
                      rel="apple-touch-icon-precomposed"
                      sizes="114x114"
                      href={`${prefix.filePrefix}/favicons/apple-touch-icon-114x114.png`}
                    />
                    <link
                      rel="apple-touch-icon-precomposed"
                      sizes="76x76"
                      href={`${prefix.filePrefix}/favicons/apple-touch-icon-76x76.png`}
                    />
                    <link
                      rel="apple-touch-icon-precomposed"
                      sizes="72x72"
                      href={`${prefix.filePrefix}/favicons/apple-touch-icon-72x72.png`}
                    />
                    <link
                      rel="apple-touch-icon-precomposed"
                      sizes="57x57"
                      href={`${prefix.filePrefix}/favicons/apple-touch-icon-57x57.png`}
                    />
                    <link
                      rel="apple-touch-icon-precomposed"
                      sizes="60x60"
                      href={`${prefix.filePrefix}/favicons/apple-touch-icon-60x60.png`}
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="180x180"
                      href={`${prefix.filePrefix}/favicons/apple-touch-icon-180x180.png`}
                    />
                    <link
                      rel="mask-icon"
                      href={`${prefix.filePrefix}/favicons/safari-pinned-tab.svg`}
                      color="#00a9d9"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={`${prefix.filePrefix}/favicons/favicon-196x196.png`}
                      sizes="196x196"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={`${prefix.filePrefix}/favicons/favicon-128.png`}
                      sizes="128x128"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={`${prefix.filePrefix}/favicons/favicon-96x96.png`}
                      sizes="96x96"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={`${prefix.filePrefix}/favicons/favicon-32x32.png`}
                      sizes="32x32"
                    />
                    <link
                      rel="icon"
                      sizes="16x16 32x32"
                      href={`${prefix.filePrefix}/favicon.ico`}
                    />
                    <meta name="referrer" content="no-referrer" />
                    <meta name="msapplication-TileColor" content="#2b2b2b" />
                    <meta
                      name="msapplication-TileImage"
                      content={`${prefix.filePrefix}/favicons/mstile-144x144.png`}
                    />
                    <meta
                      name="msapplication-square70x70logo"
                      content={`${prefix.filePrefix}/favicons/mstile-70x70.png`}
                    />
                    <meta
                      name="msapplication-square150x150logo"
                      content={`${prefix.filePrefix}/favicons/mstile-150x150.png`}
                    />
                    <meta
                      name="msapplication-wide310x150logo"
                      content={`${prefix.filePrefix}/favicons/mstile-310x150.png`}
                    />
                    <meta
                      name="msapplication-square310x310logo"
                      content={`${prefix.filePrefix}/favicons/mstile-310x310.png`}
                    />
                    {/* PWA's manifest.json */}
                    <link
                      rel="manifest"
                      href={`${prefix.filePrefix}/manifest.json`}
                    />
                    <link
                      href="//fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"
                      rel="stylesheet"
                    />
                    <link
                      rel="stylesheet"
                      href="//fonts.googleapis.com/icon?family=Material+Icons"
                      crossOrigin="anonymous"
                    />
                    <link
                      rel="stylesheet"
                      href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                      crossOrigin="anonymous"
                    />
                    <link
                      rel="stylesheet"
                      href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                      crossOrigin="anonymous"
                    />
                    <link
                      rel="stylesheet"
                      href={`${prefix.filePrefix}/css/react-toolbox.css`}
                      crossOrigin="anonymous"
                    />
                  </Helmet>
                  <ThemeProvider theme={theme}>
                    <div className={styles.app}>
                      <Switch>
                        <Redirect exact from="/" to="/home" />
                        {/* After logging in, redirect to /home/account first for listing account */}
                        <Redirect exact from="/home" to="/home/account" />

                        {/* Non-Auth Route */}
                        <Route exact path="/auth/login">
                          <Login />
                        </Route>
                        <Route exact path="/auth/reset-password">
                          <ResetPassword />
                        </Route>
                        <Route exact path="/auth/forget-password">
                          <ForgetPassword />
                        </Route>
                        <Route exact path="/invitation/accept">
                          <OrgMemberInvite />
                        </Route>

                        {/* Protected Route */}
                        {/* Use URL Array to reduce duplicated route setup, For more: https://reactrouter.com/web/api/Route/path-string-string */}
                        <Route path={routes.map((route) => route.path)}>
                          <Layout />
                        </Route>

                        {/* Common 404 fallback */}
                        <Route component={Error404} />
                      </Switch>
                    </div>
                  </ThemeProvider>
                </I18nextProvider>
              </Suspense>
            </ConfigProvider>
          </QueryClientProvider>
        </Provider>
      </Router>
    );
  }
}
