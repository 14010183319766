/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ProfilePartialUpdatePayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class User<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Auth
   * @name ProfileList
   * @summary Show current user information
   * @request GET:/user/profile
   * @secure
   * @response `200` `{ data: { id: number, name: string, email: string, is_valid: number, is_active: number, language: string, last_login_at: number | null, last_interact_acc: number | null, organization_id: (number)[], organization_role: string, accounts: ({ id: number, status: ("WAITING" | "ACTIVE" | "PAUSE" | "INCOMPLETE"), is_oversea: boolean, auth_type: ("COMPONENT" | "MANUAL"), nickname: string | null, user_name: string | null, is_verified: number | null, service_type: ("SERVICE" | "SUBSCRIPTION"), app_id: string | null, app_secret: string | null, qrcode_url: string | null, profile_url: string | null, connector_token: string | null, connector_url: string | null, aes_key: string | null, hash_id: string | null, is_open_store: number | null, is_open_scan: number | null, is_open_pay: number | null, is_open_card: number | null, is_open_shake: number | null, is_historical: number | null, is_verified_quali: number | null, is_verified_name: number | null, verify_source: string | null, func_info: string | null, business_info: string | null, permissions: { message_management: boolean, user_management: boolean, account_service: boolean, web_service: boolean, wechat_store: boolean, customer_service: boolean, broadcast_and_notice: boolean, wechat_card: boolean, wechat_scan: boolean, wechat_wifi: boolean, material_management: boolean, wechat_shake: boolean, wechat_poi: boolean, wechat_payment: boolean, custom_menu: boolean }, member_ids: (number)[], member_admin: (number)[], member_editor: (number)[], member_analyst: (number)[], member_guest: (number)[], role_name: string, follower_count: number, organization_ids: (number)[] })[] } }` Return current user information
   */
  profileList = (params: RequestParams = {}) =>
    this.request<
      {
        data: {
          id: number;
          name: string;
          email: string;
          is_valid: number;
          is_active: number;
          language: string;
          last_login_at: number | null;
          last_interact_acc: number | null;
          organization_id: number[];
          organization_role: string;
          accounts: {
            id: number;
            status: "WAITING" | "ACTIVE" | "PAUSE" | "INCOMPLETE";
            is_oversea: boolean;
            auth_type: "COMPONENT" | "MANUAL";
            nickname: string | null;
            user_name: string | null;
            is_verified: number | null;
            service_type: "SERVICE" | "SUBSCRIPTION";
            app_id: string | null;
            app_secret: string | null;
            qrcode_url: string | null;
            profile_url: string | null;
            connector_token: string | null;
            connector_url: string | null;
            aes_key: string | null;
            hash_id: string | null;
            is_open_store: number | null;
            is_open_scan: number | null;
            is_open_pay: number | null;
            is_open_card: number | null;
            is_open_shake: number | null;
            is_historical: number | null;
            is_verified_quali: number | null;
            is_verified_name: number | null;
            verify_source: string | null;
            func_info: string | null;
            business_info: string | null;
            permissions: {
              message_management: boolean;
              user_management: boolean;
              account_service: boolean;
              web_service: boolean;
              wechat_store: boolean;
              customer_service: boolean;
              broadcast_and_notice: boolean;
              wechat_card: boolean;
              wechat_scan: boolean;
              wechat_wifi: boolean;
              material_management: boolean;
              wechat_shake: boolean;
              wechat_poi: boolean;
              wechat_payment: boolean;
              custom_menu: boolean;
            };
            member_ids: number[];
            member_admin: number[];
            member_editor: number[];
            member_analyst: number[];
            member_guest: number[];
            role_name: string;
            follower_count: number;
            organization_ids: number[];
          }[];
        };
      },
      any
    >({
      path: `/user/profile`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name ProfilePartialUpdate
   * @summary Update user profile
   * @request PATCH:/user/profile
   * @secure
   * @response `200` `{ data: { id: number, name: string, email: string, is_valid: number, is_active: number, language: string, last_login_at: number | null, last_interact_acc: number | null, organization_id: (number)[], organization_role: string, accounts: ({ id: number, status: ("WAITING" | "ACTIVE" | "PAUSE" | "INCOMPLETE"), is_oversea: boolean, auth_type: ("COMPONENT" | "MANUAL"), nickname: string | null, user_name: string | null, is_verified: number | null, service_type: ("SERVICE" | "SUBSCRIPTION"), app_id: string | null, app_secret: string | null, qrcode_url: string | null, profile_url: string | null, connector_token: string | null, connector_url: string | null, aes_key: string | null, hash_id: string | null, is_open_store: number | null, is_open_scan: number | null, is_open_pay: number | null, is_open_card: number | null, is_open_shake: number | null, is_historical: number | null, is_verified_quali: number | null, is_verified_name: number | null, verify_source: string | null, func_info: string | null, business_info: string | null, permissions: { message_management: boolean, user_management: boolean, account_service: boolean, web_service: boolean, wechat_store: boolean, customer_service: boolean, broadcast_and_notice: boolean, wechat_card: boolean, wechat_scan: boolean, wechat_wifi: boolean, material_management: boolean, wechat_shake: boolean, wechat_poi: boolean, wechat_payment: boolean, custom_menu: boolean }, member_ids: (number)[], member_admin: (number)[], member_editor: (number)[], member_analyst: (number)[], member_guest: (number)[], role_name: string, follower_count: number, organization_ids: (number)[] })[] } }` Successfully updated the user profile and get the updated user profile as result
   */
  profilePartialUpdate = (data: ProfilePartialUpdatePayload, params: RequestParams = {}) =>
    this.request<
      {
        data: {
          id: number;
          name: string;
          email: string;
          is_valid: number;
          is_active: number;
          language: string;
          last_login_at: number | null;
          last_interact_acc: number | null;
          organization_id: number[];
          organization_role: string;
          accounts: {
            id: number;
            status: "WAITING" | "ACTIVE" | "PAUSE" | "INCOMPLETE";
            is_oversea: boolean;
            auth_type: "COMPONENT" | "MANUAL";
            nickname: string | null;
            user_name: string | null;
            is_verified: number | null;
            service_type: "SERVICE" | "SUBSCRIPTION";
            app_id: string | null;
            app_secret: string | null;
            qrcode_url: string | null;
            profile_url: string | null;
            connector_token: string | null;
            connector_url: string | null;
            aes_key: string | null;
            hash_id: string | null;
            is_open_store: number | null;
            is_open_scan: number | null;
            is_open_pay: number | null;
            is_open_card: number | null;
            is_open_shake: number | null;
            is_historical: number | null;
            is_verified_quali: number | null;
            is_verified_name: number | null;
            verify_source: string | null;
            func_info: string | null;
            business_info: string | null;
            permissions: {
              message_management: boolean;
              user_management: boolean;
              account_service: boolean;
              web_service: boolean;
              wechat_store: boolean;
              customer_service: boolean;
              broadcast_and_notice: boolean;
              wechat_card: boolean;
              wechat_scan: boolean;
              wechat_wifi: boolean;
              material_management: boolean;
              wechat_shake: boolean;
              wechat_poi: boolean;
              wechat_payment: boolean;
              custom_menu: boolean;
            };
            member_ids: number[];
            member_admin: number[];
            member_editor: number[];
            member_analyst: number[];
            member_guest: number[];
            role_name: string;
            follower_count: number;
            organization_ids: number[];
          }[];
        };
      },
      any
    >({
      path: `/user/profile`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
