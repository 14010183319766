/* Orientation */
/* Devices (defined by max width) */
/* Devices (defined by min-width) */
.wrapper {
  position: relative;
  z-index: 200;
}
.drawer {
  background-color: rgb(250, 250, 250);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: rgb(66, 66, 66);
  display: block;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform-style: preserve-3d;
  transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0s;
  width: 280px;
  will-change: transform;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%
}
.drawer *,
    .drawer *::after,
    .drawer *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.drawer.active {
    pointer-events: all;
    transform: translateX(0);
    transition-delay: 0.07s;
  }
.drawer.right {
    border-left: 1px solid rgb(224, 224, 224);
    right: 0
  }
.drawer.right:not(.active) {
      transform: translateX(100%);
    }
.drawer.left {
    border-right: 1px solid rgb(224, 224, 224);
    left: 0
  }
.drawer.left:not(.active) {
      transform: translateX(-100%);
    }
@media screen and (min-width: 600px) {
  .drawer {
    width: 320px;
  }
}
