.overlay {
  background-color: rgb(0, 0, 0);
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100vw
}
.overlay.active {
    opacity: 0.6;
    pointer-events: all;
  }
