.field {
  display: block;
  height: 18px;
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%
}
.field *,
    .field *::after,
    .field *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.field .ripple {
    background-color: rgb(63, 81, 181);
    opacity: 0.3;
    transition-duration: 650ms;
  }
.text {
  color: rgb(0, 0, 0);
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  padding-left: 10px;
  vertical-align: top;
  white-space: nowrap;
}
.check {
  border-color: rgb(117, 117, 117);
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  position: relative;
  transition-duration: 0.2s;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: top;
  width: 18px;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%
}
.check *,
    .check *::after,
    .check *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.check.checked {
    background-color: rgb(63, 81, 181);
    border-color: rgb(63, 81, 181)
  }
.check.checked::after {
      animation: checkmark-expand 140ms ease-out forwards;
      border-bottom-width: 2px;
      border-color: rgb(255, 255, 255);
      border-left: 0;
      border-right-width: 2px;
      border-style: solid;
      border-top: 0;
      content: '';
      height: 12px;
      left: 4px;
      position: absolute;
      top: -1px;
      transform: rotate(45deg);
      width: 7px;
    }
.disabled > .text {
    color: rgba(0, 0, 0, 0.26);
  }
.disabled > .check {
    border-color: rgba(0, 0, 0, 0.26);
    cursor: auto
  }
.disabled > .check.checked {
      background-color: rgba(0, 0, 0, 0.26);
      border-color: transparent;
      cursor: auto;
    }
.input {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0
}
.input:focus ~ .check::before {
      background-color: rgba(0, 0, 0, 0.01);
      border-radius: 50%;
      content: '';
      height: 41.4px;
      left: 50%;
      margin-left: -20.7px;
      margin-top: -20.7px;
      pointer-events: none;
      position: absolute;
      top: 50%;
      width: 41.4px;
    }
.input:focus ~ .check.checked::before {
      background-color: rgba(63, 81, 181, 0.26);
    }
@keyframes checkmark-expand {
  0% {
    height: 0;
    left: 6px;
    top: 9px;
    width: 0;
  }

  100% {
    height: 12px;
    left: 4px;
    top: -1px;
    width: 7px;
  }
}
