import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetUserProfileList } from "/src/utils/reactQuery/user";
import { useDispatch } from "react-redux";
import { loggedOut, setUser } from "/src/store/reducers/auth";
import { useAppSelector } from "/src/store/hooks";
import { Redirect } from "react-router-dom";

// it's for checking the login status
// if user logged in, commit user information to redux
// if user is logged out (by clicking logout button, or API error 401), redirect user to login page
function LoginStatus(props: { children: JSX.Element }): JSX.Element {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const loggedIn = useAppSelector((state) => state.auth.login);
  const user = useAppSelector((state) => state.auth.user);

  const userProfileList = useGetUserProfileList();
  useEffect(() => {
    if (
      loggedIn &&
      !user &&
      userProfileList.isFetched &&
      !userProfileList.isError
    ) {
      const userData = userProfileList.data?.data?.data;
      if (userData) {
        dispatch(setUser(userData));
        i18n.changeLanguage(userData.language);
      }
    }
    if (userProfileList.isError) {
      dispatch(loggedOut());
    }
  }, [
    userProfileList.isFetched,
    userProfileList.isError,
    dispatch,
    user,
    userProfileList.data?.data?.data,
  ]);
  if (loggedIn === false) {
    return <Redirect to={"/auth/login"} />;
  }
  if (!user) {
    return <div></div>;
  }
  return props.children;
}

export default LoginStatus;
