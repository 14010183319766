.container {
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 350px;
  height: 240px;
  margin: auto;
}

.value {
  font-size: 1.7rem;
}

.messageContainer {
  text-align: center;
  p {
    padding: 1rem;
  }
}