import React from "react";
import { Input } from "react-toolbox";
import type { FieldRenderProps } from "react-final-form";
/**
 * This interface is for wrapping up a common input for react-final-form "Field" component
 */
export interface InputProps<T = string> extends FieldRenderProps<T> {
  label?: string;
  maxLength?: number;
  acceptFormat?: string;
  errorMsg?: string;
  multiline?: boolean;
  disabled?: boolean;
}

function RenderInput<T>({
  input,
  label,
  maxLength,
  multiline,
  acceptFormat,
  meta: { touched, error },
  errorMsg,
  disabled,
}: InputProps<T>): JSX.Element {
  let errorMessage = "";
  if (touched) {
    errorMessage = error;
  }

  if (errorMsg) {
    errorMessage = errorMsg;
  }
  if (error) {
    errorMessage = error;
  }
  return (
    <Input
      {...input}
      label={label}
      disabled={!!disabled}
      maxLength={maxLength}
      multiline={multiline}
      error={errorMessage}
      // @ts-expect-error: Not sure if this line should be kept when refactoring from jsx to tsx, so just leave it as is
      accept={acceptFormat}
    />
  );
}

export default RenderInput;
