import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "/src/store/store";

// Define a type for the slice state
interface ErrorState {
  errorMsg: string;
}

// Define the initial state using that type
const initialState: ErrorState = {
  errorMsg: "",
};

export const errorSlice = createSlice({
  name: "error",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateErrorMsg: (state, action: PayloadAction<string>) => {
      state.errorMsg = action.payload;
    },
  },
});

export const { updateErrorMsg } = errorSlice.actions;

export default errorSlice.reducer;
