import axios from "axios";
import * as LaravelError from "/src/types/api/laravelError";
import isPlainObject from "lodash-es/isPlainObject";
import { ApiError } from "/src/types/api/ApiErrorType";

interface ApiValidationError {
  [key: string]: string[];
}

const typeChecking = <T extends { error?: unknown }>(
  errorObject: unknown
): errorObject is T => {
  //return true only if errorObject is object, has error params and error params is plain object
  return (
    (errorObject as T).error !== undefined &&
    isPlainObject((errorObject as T).error)
  );
};

export const apiErrorHandling = (error: unknown): ApiError | null => {
  if (error === undefined || error === null) {
    return null;
  }
  // if error is already ApiError, just return it as is
  if (
    isPlainObject(error as ApiError) &&
    (error as ApiError).message !== undefined
  ) {
    return error as ApiError;
  }
  let errorMsg = "";
  const defaultErrorMsg =
    "Something went wrong, please try again. If problem persists, please contact administrator.";

  let errStatus: number | undefined;
  let apiError: ApiValidationError | undefined;
  if (!axios.isAxiosError(error)) {
    console.error(error);
    return { status: errStatus, message: defaultErrorMsg };
  }
  switch (error.response?.status) {
    case 422:
      //error 422 - Unprocessable Entity/laravel validation error (e.g. field missing or incorrect type when submitting etc.)
      //for 422 error, there will have array on error message
      if (typeChecking<LaravelError.ValidationError>(error.response.data)) {
        apiError = error.response.data.error.errors ?? {};

        for (const [, value] of Object.entries(apiError)) {
          for (const err of value) {
            errorMsg += `${err}\n`;
          }
        }
      }
      errStatus = error.response.status;
      break;
    default:
      //for other error(e.g. 404, 400), the error message will be a simple string
      //for error 401, the login status has handled before this handling function
      if (typeChecking<LaravelError.GeneralError>(error?.response?.data)) {
        errorMsg = error?.response?.data.error.message ?? "";
        errStatus = error.response?.status;
      }
      break;
  }

  //if errorMsg still empty after run through error handling, set default error msg
  if (errorMsg === "") {
    errorMsg = defaultErrorMsg;
  }
  return { status: errStatus, message: errorMsg };
};
