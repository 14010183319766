import { createSlice, Dictionary, PayloadAction } from "@reduxjs/toolkit";
import {
  omitBy,
  isEmpty,
  cloneDeep,
  merge,
  pickBy,
  isBoolean,
} from "lodash-es";
import type { RootState } from "/src/store/store";
import type { AccountFollowerSearchDetailParams } from "/src/types/api/data-contracts";
import { Follower } from "utils/reactQuery/follower";

export interface Genders {
  MALE: string;
  FEMALE: string;
  UNDEFINED: string;
}

export interface Languages {
  zh_CN: string;
  zh_TW: string;
  zh_HK: string;
  en: string;
}

export type FilterListFormValue = Omit<
  AccountFollowerSearchDetailParams,
  "orgId" | "accId" | "page" | "count"
>;

// Define a type for the slice state
interface FollowerState {
  filter: FilterListFormValue;
  filterForQuery: Omit<AccountFollowerSearchDetailParams, "orgId" | "accId">;
  activeFollower: Follower | null;
  selectedFollowerIds: number[];
}

const getDefaultFilterValues = (): FilterListFormValue => ({
  gender: [],
  geo_id: [],
  is_blacklist: false,
  is_subscribed: false,
  lang: [],
  remark: "",
  tag_ids: [],
});

// Define the initial state using that type
const initialState: FollowerState = {
  filter: getDefaultFilterValues(),
  filterForQuery: {},
  activeFollower: null,
  selectedFollowerIds: [],
};

export const followerSlice = createSlice({
  name: "follower",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<FilterListFormValue>) => {
      state.filter = action.payload;
      //use isEmpty and isBoolean to filter empty array and empty string
      //since isEmpty can only check if the variable is object, string, array, map and set which it will filter out boolean
      //so we need use isBoolean to find out all boolean field and merge with isEmpty
      const tempFilterForQuery: Dictionary<unknown> = merge(
        omitBy(cloneDeep(action.payload), isEmpty),
        pickBy(cloneDeep(action.payload), isBoolean)
      );
      for (const [key, value] of Object.entries(tempFilterForQuery)) {
        if (Array.isArray(value) && isEmpty(value) === false) {
          tempFilterForQuery[key] = value.join(",");
        } else if (isBoolean(value)) {
          //transform true value to 1 and remove false value from temp filter query
          if (value) tempFilterForQuery[key] = "1";
          else delete tempFilterForQuery[key];
        }
      }
      state.filterForQuery = tempFilterForQuery;
    },
    setActiveFollower: (state, action: PayloadAction<Follower | null>) => {
      state.activeFollower = action.payload;
    },
    setSelectedFollowerIds: (state, action: PayloadAction<number[]>) => {
      state.selectedFollowerIds = action.payload;
    },
  },
});

export const { setFilter, setActiveFollower, setSelectedFollowerIds } =
  followerSlice.actions;

export default followerSlice.reducer;
