@use 'sass:math';
.app {
  .brand {
    position: absolute;
    $size: 40px;
    top: 5px;
    left: 5px;
    display: inline-block;
    width: $size;
    height: $size;
    background-size: 80%;
    margin: 0 10px 0 0;
    border-radius: math.div($size, 2);
  }

  nav :global(.fa) {
    font-size: 2em;
    line-height: 20px;
  }
}

.layout {
  position: fixed;
}

.appContent {
  margin: 50px 0; // for fixed navbar
}

.navDrawer {
  top: 64px;
  position: fixed;
}

//temp fix on incorrect layout for html p tag
p {
  margin-bottom: 0 !important;
}
