import memoize from "lru-memoize";
import {
  createValidator,
  required,
  minLength,
  confirm,
} from "utils/validation";

const acceptFormValidation = createValidator({
  name: [required],
  password: [minLength(8), required],
  password_confirmation: [minLength(8), required, confirm("password")],
});

export default memoize(10)(acceptFormValidation);
