import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "react-toolbox";
import { compose } from "redux";
import { reduxForm, Field, propTypes } from "redux-form";
import forgetPasswordValidation from "./forgetPasswordValidation";

import RenderInput from "/src/components/Input/FormInput";

const initialVal = {
  email: "",
};

class ForgetPasswordForm extends Component {
  state = {
    ...initialVal,
  };

  getErrorMessage = (field) =>
    this.props.errorMsg ? this.props.errorMsg[field] : null;

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  render() {
    const {
      handleSubmit,
      onClickSubmit,
      isFormSubmitting,
      submitting,
      submit,
      t,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="email"
          type="email"
          label={t("forget_password.email_field")}
          component={RenderInput}
          errorMsg={this.getErrorMessage("email")}
          required
        />
        <Button
          label={t("forget_password.action.submit")}
          type="submit"
          onClick={submit}
          disabled={submitting || isFormSubmitting}
          raised
          primary
          style={{ width: "100%" }}
        />
      </form>
    );
  }
}

ForgetPasswordForm.propTypes = {
  ...propTypes,
  errorMsg: PropTypes.object,
  isFormSubmitting: PropTypes.bool.isRequired,
};

export default compose(
  reduxForm({
    form: "forgetPassword",
    initialValues: initialVal,
    validate: forgetPasswordValidation,
  }),
  withTranslation("auth")
)(ForgetPasswordForm);
