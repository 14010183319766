import axios, { AxiosError } from "axios";
import type { AxiosInstance, AxiosRequestConfig } from "axios";
import { Org } from "/src/types/api/Org";
import { User } from "/src/types/api/User";
import { store } from "/src/store/store";
import { loggedOut, selectAccount } from "/src/store/reducers/auth";
import { apiErrorHandling } from "../common/apiErrorHandle";
import { Invite } from "/src/types/api/Invite";
import { logout } from "../types/api/Login";

const headers = {
  "X-Requested-With": "XMLHttpRequest",
  Accept: "application/json",
};

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.WEB_URL ?? "/cms-api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    ...headers,
  },
  paramsSerializer: function (params: Record<string, unknown>) {
    const str = [];
    for (const p in params)
      if (Object.prototype.hasOwnProperty.call(params, p)) {
        str.push(p + "=" + params[p]);
      }
    return str.join("&");
  },
};
const requestErrorInterceptor = async (error: AxiosError) => {
  if (error.response?.status === 401) {
    // tell redux to change login state to false
    // Layout componant will handle the actual redirection to /auth/login
    store.dispatch(loggedOut());
  } else if (error.response?.status === 403) {
    // try to get the fist account from account list
    const accountList = store.getState().auth.user?.accounts;
    if (accountList && accountList.length > 0) {
      // switch to the account
      store.dispatch(selectAccount(accountList[0]));
    } else {
      // no account owned by user, logout and redirect to login page
      await logout();
      store.dispatch(loggedOut());
    }
  }
  //pack the error response to status and message Object, so frontend can show it easily
  const errorMsg = apiErrorHandling(error);

  return Promise.reject(errorMsg);
};

// The default api client, in case calling api which is not included in the api doc is necessary
// You should not use it for calling interal API, since it means that the API doc is incomplete.
// You should update the API doc first on backend step-matrix project, and generate the API client again using swagger-typescript-api
export const api = axios.create(axiosConfig);
api.interceptors.response.use((response) => {
  return response;
}, requestErrorInterceptor);

// api clients from library swagger-typescript-api generator with custom axios config
export const orgApi = new Org(axiosConfig);
orgApi.instance.interceptors.response.use((response) => {
  return response;
}, requestErrorInterceptor);
export const userApi = new User(axiosConfig);
userApi.instance.interceptors.response.use((response) => {
  return response;
}, requestErrorInterceptor);
export const inviteApi = new Invite(axiosConfig);
inviteApi.instance.interceptors.response.use((response) => {
  return response;
}, requestErrorInterceptor);
// login API use api as prefix, not cms-api, so need to replace it
export const loginApi = axios.create({
  ...axiosConfig,
  baseURL: process.env.WEB_URL
    ? process.env.WEB_URL.replace("cms-api", "api")
    : "/api",
});
loginApi.interceptors.response.use((response) => {
  return response;
}, requestErrorInterceptor);
