
  .value > input {
    cursor: pointer;
  }
.value::after {
    border-left: 5.48571px solid transparent;
    border-right: 5.48571px solid transparent;
    border-top: 5.48571px solid rgba(33, 33, 33, 0.12);
    content: '';
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 8px;
    top: 50%;
    transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    width: 0;
  }
.label {
  color: rgba(33, 33, 33, 0.26);
  font-size: 12px;
  left: 0;
  line-height: 16px;
  position: absolute;
  top: 6px
}
.label .required {
    color: rgb(222, 50, 38);
  }
.values {
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  transition-duration: 0.35s;
  transition-property: max-height, box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  z-index: 100
}
.values > * {
    cursor: pointer;
    overflow: hidden;
    padding: 10px;
    position: relative
  }
.values > *:hover:not(.disabled) {
      background-color: rgb(238, 238, 238);
    }
.values > *.selected {
      color: rgb(63, 81, 181);
    }
.values > *.disabled {
      color: rgba(0, 0, 0, 0.26);
      cursor: not-allowed;
    }
.values::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
.dropdown {
  position: relative;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%
}
.dropdown *,
    .dropdown *::after,
    .dropdown *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
    }
.dropdown:not(.active) > .values {
      max-height: 0;
      visibility: hidden;
    }
.dropdown.active > .label,
    .dropdown.active > .value {
      opacity: 0.5;
    }
.dropdown.active > .values {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
      max-height: 45vh;
      visibility: visible;
    }
.dropdown:not(.up) > .values {
    bottom: auto;
    top: 0;
  }
.dropdown.up > .values {
    bottom: 0;
    top: auto;
  }
.dropdown.disabled {
    cursor: normal;
    pointer-events: none;
  }
.templateValue {
  background-color: transparent;
  border-bottom: 1px solid rgba(33, 33, 33, 0.12);
  color: rgb(33, 33, 33);
  min-height: 38.4px;
  padding: 8px 0;
  position: relative;
}
.field {
  cursor: pointer;
  padding: 20px 0;
  position: relative
}
.field.errored {
    padding-bottom: 0
  }
.field.errored > .label {
      color: rgb(222, 50, 38);
    }
.field.errored > .templateValue {
      border-bottom: 1px solid rgb(222, 50, 38);
    }
.field.errored > .label > .required {
      color: rgb(222, 50, 38);
    }
.field.disabled {
    cursor: normal;
    pointer-events: none
  }
.field.disabled > .templateValue {
      border-bottom-style: dotted;
      opacity: 0.7;
    }
.error {
  color: rgb(222, 50, 38);
  font-size: 12px;
  line-height: 20px;
  margin-bottom: -20px;
}
