"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FontIcon", {
  enumerable: true,
  get: function get() {
    return _FontIcon.FontIcon;
  }
});
exports.default = void 0;

var _FontIcon = require("./FontIcon");

var _default = _FontIcon.FontIcon;
exports.default = _default;